import React from 'react'

export default {
    MYSELF: <svg id="Layer_1" style={{paddingTop: '10px'}} width="100%" height="100"data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.08 87.88">
        <defs>
            <style>{'.cls-1{fill:#454545;}'}</style>
        </defs>
        <title>myself</title>
        <g id="Group_303" data-name="Group 303">
            <path id="Path_44" data-name="Path 44" className="cls-1"
                  d="M33,44.26V55.54c0,2.87-1.28,4.7-4.26,4.88-2.08.12-2.5,1.28-2.44,3,.06,6.22.06,12.38,0,18.6,0,4.39-1.46,5.85-5.86,5.85-2.74,0-5.48.06-8.23,0-3.78-.06-5.36-1.64-5.42-5.36-.06-6.22-.06-12.38,0-18.6,0-2.13-.37-3.47-2.93-3.6S.12,58.46.12,56c-.06-7.74-.24-15.6,0-23.41a8.38,8.38,0,0,1,8.7-8,8.7,8.7,0,0,1,2.09.35,21.22,21.22,0,0,0,11.4,0A8.41,8.41,0,0,1,32.68,30.7a8.17,8.17,0,0,1,.3,2c.19,3.84.06,7.74.06,11.58Z" transform="translate(0 0)"/>
            <path id="Path_45" data-name="Path 45" className="cls-1" d="M16.34,22a10.81,10.81,0,0,1-10.8-10.8c0-.14,0-.28,0-.42A11,11,0,1,1,16.54,22Z" transform="translate(0 0)"/>
        </g>
    </svg>,
    MINOR: <svg id="Layer_1" style={{paddingTop: '10px'}} width="100%" height="65" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.72 59.73">
        <defs>
            <style>{'.cls-1{fill:#454545;}'}</style>
        </defs>
        <title>minor</title>
        <g id="Group_306" data-name="Group 306">
            <path id="Path_42" data-name="Path 42" className="cls-1" d="M21.51,50.43c0,1.66,0,3.31,0,5-.08,3-1.65,4.3-4.88,4.26-3.06,0-4.63-1.37-4.63-4.3,0-9.19-.09-18.33,0-27.52a6.29,6.29,0,0,0-2.15-5.25C7,19.86,4.22,16.92,1.41,14.11c-1.78-1.78-1.87-3.85-.29-5.46s3.64-1.57,5.46.2c2.85,2.78,5.71,5.63,8.48,8.49a3.59,3.59,0,0,0,3.1,1.28h.21c3.22,0,6.7.62,9.64-.37s4.71-4.26,7.07-6.46c1.08-1,2-2.07,3.1-3.06,1.82-1.7,3.89-1.65,5.5,0,1.45,1.49,1.41,3.43-.25,5.17-3,3.15-6.08,6.21-9.18,9.23A4.92,4.92,0,0,0,32.68,27c0,9.18-.09,18.32.08,27.51.08,4.68-2.86,5.79-6.7,5-2-.42-2.9-2-2.9-4v-9.1a11.37,11.37,0,0,0,0-1.65c0-.58,0-1.41-.87-1.37-.7,0-.7.75-.7,1.29,0,1.9,0,3.84,0,5.79Z" transform="translate(0 0)"/>
            <path id="Path_90" data-name="Path 90" className="cls-1" d="M31.7,8.36A8.32,8.32,0,1,1,15.07,9a5,5,0,0,1,0-.55A8.12,8.12,0,0,1,22.91,0h.43A8.23,8.23,0,0,1,31.7,8.1Z" transform="translate(0 0)"/>
        </g>
    </svg>,
    BOTH: <svg id="Layer_1" style={{paddingTop: '10px'}} width="100%" height="100" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.72 87.88">
        <defs>
            <style>{'.cls-1{fill:#454545;}'}</style>
        </defs>
        <title>both</title>
        <g id="Group_305" data-name="Group 305">
            <g id="Group_304" data-name="Group 304">
                <path id="Path_44" data-name="Path 44" className="cls-1" d="M33.05,44.26V55.54c0,2.87-1.28,4.7-4.27,4.88-2.08.12-2.5,1.28-2.44,3,.06,6.22.06,12.38,0,18.6,0,4.39-1.46,5.85-5.85,5.85-2.75,0-5.49.06-8.23,0-3.78-.06-5.37-1.64-5.43-5.36-.06-6.22-.06-12.38,0-18.6,0-2.13-.37-3.47-2.93-3.6S.12,58.46.12,56c-.06-7.74-.24-15.6,0-23.41a8.38,8.38,0,0,1,8.7-8,8.7,8.7,0,0,1,2.09.35,21.22,21.22,0,0,0,11.4,0A8.41,8.41,0,0,1,32.68,30.7a8.17,8.17,0,0,1,.3,2c.19,3.84.06,7.74.06,11.58Z" transform="translate(0 0)"/>
                <path id="Path_45" data-name="Path 45" className="cls-1" d="M16.34,22a10.81,10.81,0,0,1-10.8-10.8c0-.14,0-.28,0-.42A11,11,0,1,1,16.54,22Z" transform="translate(0 0)"/>
            </g>
            <path id="Path_42" data-name="Path 42" className="cls-1" d="M56.51,78.43c0,1.66,0,3.31,0,5-.08,3-1.65,4.3-4.88,4.26-3.06,0-4.63-1.37-4.63-4.3,0-9.19-.09-18.33,0-27.52a6.29,6.29,0,0,0-2.15-5.25c-2.9-2.73-5.63-5.67-8.44-8.48-1.78-1.78-1.87-3.85-.29-5.46s3.64-1.57,5.46.2c2.85,2.78,5.71,5.63,8.48,8.49a3.59,3.59,0,0,0,3.1,1.28h.21c3.22,0,6.7.62,9.64-.37s4.71-4.26,7.07-6.46c1.08-1,2-2.07,3.1-3.06,1.82-1.7,3.89-1.65,5.5,0,1.45,1.49,1.41,3.43-.25,5.17-3,3.15-6.08,6.21-9.18,9.23A4.92,4.92,0,0,0,67.68,55c0,9.18-.09,18.32.08,27.51.08,4.68-2.86,5.79-6.7,5-2-.42-2.9-2-2.9-4v-9.1a11.37,11.37,0,0,0,0-1.65c0-.58,0-1.41-.87-1.37-.7,0-.7.75-.7,1.29,0,1.9,0,3.84,0,5.79Z" transform="translate(0 0)"/>
            <path id="Path_43" data-name="Path 43" className="cls-1" d="M65.7,36.36A8.32,8.32,0,1,1,49.07,37a5,5,0,0,1,0-.55A8.12,8.12,0,0,1,56.91,28h.43a8.23,8.23,0,0,1,8.36,8.1Z" transform="translate(0 0)"/>
        </g>
    </svg>
}