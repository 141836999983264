import { SIGNING } from '../constants'

/**
 * Set signingSession's data into store.
 *
 * @function signingSession
 *
 * This reducer processes one of defined actions' types:
 * [SIGNING.SESSION.SUCCESS, SIGNING.SESSION.FAIL, CANCEL.SUCCESS].
 * In any other case it returns the state which it got as a first param.
 *
 * On SIGNING.SESSION.SUCCESS action the function sets signingSession's data into store
 * On CANCEL.SUCCESS action the function clears revokeSignedWaiver and sets empty array into store
 * On SIGNING.SESSION.FAIL action the function returns current value of signingSession
 *
 * @param {Object} state - Current value of the signingSession field of the store (default value is an empty object)
 *
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of signingSession
 *
 */

export const signingSession = (state = {}, action) => {
    switch (action.type) {
        case SIGNING.SESSION.SUCCESS:
            return action.payload
        case SIGNING.SESSION.FAIL:
        default:
            return state
    }
}
