import { CANCEL, DOCUMENT }  from '../constants'

/**
 * Set documentForSigning's data into store.
 *
 * This reducer processes one of defined actions' types:
 * [DOCUMENT.SUCCESS, DOCUMENT.FAIL, CANCEL.SUCCESS].
 * In any other case it returns the state which it got as a first param.
 *
 * On DOCUMENT.SUCCESS action the function sets documentForSigning's into store
 * On DOCUMENT.FAIL and CANCEL.SUCCESS actions the function clears documentForSigning and sets empty object into store
 * @function documentForSigning
 *
 * @param {Object} state - Current value of the documentForSigning field of the store (default value is an empty object)
 *
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of documentForSigning
 *
 */

export const documentForSigning = (state = {}, action) => {
    switch (action.type) {
        case DOCUMENT.SUCCESS:
            return action.payload
        case DOCUMENT.FAIL:
            return {}
        case CANCEL.SUCCESS:
            return {}
        default:
            return state
    }
}