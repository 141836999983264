import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import { push } from 'react-router-redux'
import { Form, Header, Button, Segment, Responsive, Grid } from 'semantic-ui-react'
import MinorsCount from './minorsCount'
import { createAction } from '../../sagas/createAction'
import { WAIVER_LIST } from '../../constants'
import LanguageDropdown from '../Ui/LanguageDropdown'
import getTranslator from '../../multilingualism/getTranslator'
import fields from '../../multilingualism/fields'
import RadioWithImage from '../Custom/radioImage'
import icons from './icons'

const validate = (values, props) => {
    const errors = {}
    if (values.minorsCount === 0) {
        errors['minorsCount'] = fields.MIN_MINOR_COUNT
    }
    else if (!values.minorsCount) {
        errors['minorsCount'] = fields.SELECT_MINOR
    } else if (values.minorsCount > 15) {
        errors['minorsCount'] = {text: fields.MAXIMUM_COUNT_MINORS, value: 15}
    }
    return errors
}

class SelectParticipant extends PureComponent {
    componentDidMount() {
        const { participantOptions, change } = this.props
        if (participantOptions.signingOptions.length === 1) {
            if (participantOptions.signingOptions[0] === 'parents') {
                change('signing', 'minors')
            } else if (participantOptions.signingOptions[0] === 'adults') {
                change('signing', 'myself')
            }
        }
    }

    renderAdult = () => {
        const { participantOptions, formValues: { signing } } = this.props
        return (
            <Grid.Column key="myself" className="selectParticipant flexColumn participantCard">
                <Segment className={`radioButton hover noPadding flexColumn ${signing === 'myself' ? 'selectedParticipant' : ''}`}>
                    <Field
                        name="signing"
                        fieldName="myself"
                        type="radio"
                        value="myself"
                        img={icons.MYSELF}
                        label={getTranslator(this.props.languageSettings, this.props.waiverLanguage)(fields.MYSELF_ONLY, {years: participantOptions.adultAge})}
                        component={RadioWithImage}
                        className="fullHeight"/>
                </Segment>
            </Grid.Column>
        )
    }

    renderParents = () => {
        const { participantOptions, formValues: { signing } } = this.props
        return (
            <Grid.Column key="minors" className="selectParticipant flexColumn participantCard">
                <Segment className={`radioButton hover noPadding flexColumn ${signing === 'minors' ? 'selectedParticipant' : ''}`}>
                    <Field
                        name="signing"
                        fieldName="minors"
                        type="radio"
                        value="minors"
                        img={icons.MINOR}
                        label={getTranslator(this.props.languageSettings, this.props.waiverLanguage)(fields.MINORS_ONLY, {years: participantOptions.adultAge})}
                        component={RadioWithImage}
                        className="fullHeight"/>
                </Segment>
            </Grid.Column>
        )
    }

    renderBoth = () =>{
        const { formValues: { signing } } = this.props
        return (<Grid.Column key="both" className="selectParticipant flexColumn participantCard">
            <Segment className={`radioButton hover noPadding flexColumn ${signing === 'both' ? 'selectedParticipant' : ''}`}>
                <Field
                    name="signing"
                    fieldName="both"
                    type="radio"
                    value="both"
                    img={icons.BOTH}
                    label={getTranslator(this.props.languageSettings, this.props.waiverLanguage)(fields.MYSELF_MINORS)}
                    component={RadioWithImage}
                    className="fullHeight"/>
            </Segment>
        </Grid.Column>)
    }

    renderOptionList = () => {
        const searchParams = new URLSearchParams(document.location.search)

        const { participantOptions } = this.props
        const client = searchParams.get('client')
        return participantOptions.signingOptions.map(option => {
            if (option === 'adults') {
                return this.renderAdult()
            } else if (option === 'parents') {
                return this.renderParents()
            }
            if(client !== 'singleMinor') {
                return this.renderBoth()
            }
        })
    }

    render() {
        const {
            handleSubmit,
            pristine,
            loading,
            languageSettings,
            waiverLanguage
        } = this.props

        const searchParams = new URLSearchParams(document.location.search)
        const client = searchParams.get('client')
        const translate = getTranslator(languageSettings, waiverLanguage)
        const showLanguageDropdown = languageSettings.availableLanguages.length > 1
        return (
            <Form onSubmit={handleSubmit}>
                {showLanguageDropdown ? <Responsive as={'div'} className="ui grid" minWidth={768}>
                    <div className="three wide centered column margin-bottom">
                        <LanguageDropdown/>
                    </div>
                </Responsive> : null}
                {showLanguageDropdown ? <Responsive as={'div'} className="ui grid" maxWidth={767}>
                    <div className="seven wide centered column">
                        <LanguageDropdown/>
                    </div>
                </Responsive> : null}
                <Responsive id="headerText" as={'div'} className="margin-top" minWidth={768}>
                    <Header as='h3' textAlign="center">{translate(fields.SELECT_PARTICIPANTS)}</Header>
                </Responsive>
                <Responsive as={'div'} className="margin-top" maxWidth={767}>
                    <Header as='h3' textAlign="center" className="margin-none">{translate(fields.SELECT_PARTICIPANTS)}</Header>
                </Responsive>
                <Grid stackable className="centered padding-top" columns={3}>
                    {this.renderOptionList()}
                </Grid>
                {client === 'singleMinor' ?
                    null : <MinorsCount />
                }
                {!pristine && <div className="ui basic center aligned segment padding-none">
                    <Button id="continueButton" type="submit" primary size="big" className="margin-top" loading={loading}>{translate(fields.CONTINUE)}</Button>
                </div>}
            </Form>
        )
    }
}

const handleSubmitForm = (data, dispatch, { submitParticipantSelection, match, location }) => {
    submitParticipantSelection({ data, match, location })
}

const SelectParticipantForm = reduxForm({
    form: 'SelectParticipantForm',
    onSubmit: handleSubmitForm,
    initialValues: {
        minorsCount: 1
    },
    validate,
})(SelectParticipant)

const selector = formValueSelector('SelectParticipantForm')
export default connect(

    state => ({
        participantOptions: state.participantOptions,
        loading: state.loading,
        businessEntity: state.additionalInformation.businessEntity,
        languageSettings: state.languageSettings,
        waiverLanguage: state.waiverLanguage,
        formValues: selector(state, 'minorsCount', 'signing')
    }),
    {
        push,
        submitParticipantSelection: createAction(WAIVER_LIST)
    }
)(SelectParticipantForm)
