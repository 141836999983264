import { MINORS_DROPDOWN } from '../constants'

/**
 * Set selectParticipant's data into store.
 *
 * @function selectParticipant
 *
 * @param {Object} state - Current value of the selectParticipant field of the store (default value is an object with minorsDropdown: true)
 *
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of selectParticipant
 *
 */

export const selectParticipant = (state = {
    minorsDropdown: true
}, action) => {
    switch (action.type) {
        case MINORS_DROPDOWN.SUCCESS:
            return {
                ...state,
                minorsDropdown: action.payload
            }
        default:
            return state
    }
}