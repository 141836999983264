import { SIGNING, CANCEL } from '../constants'

/**
 * Set signingProcess's data into store.
 *
 * This reducer processes one of defined actions' types:
 * [SIGNING.PROCESS.SUCCESS, SIGNING.EVENT.SET, SIGNING.EVENT_FOR_SIGNING.SET, CANCEL.SUCCESS].
 * In any other case it returns the state which it got as a first param.
 *
 *
 * @function signingProcess
 *
 * @param {Object} state - Current value of the signingProcess field of the store (default empty object)
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of signingProcess
 *
 */

export const signingProcess = (state = {}, action) => {
    switch (action.type) {
        case SIGNING.PROCESS.SUCCESS:
            /** returns new object with all fields of the "state" and with all field of "action.payload" */
            return {
                ...state,
                ...action.payload
            }
        case SIGNING.EVENT.SET:
            /** returns new object with all fields of the "state" and with new field "event" which is assigned to "action.payload" */
            return {
                ...state,
                event: action.payload
            }
        case SIGNING.EVENT_FOR_SIGNING.SET:
            /** returns new object with all fields of the "state" and with new field "eventForSigning" which is assigned to "action.payload" */
            return {
                ...state,
                eventForSigning: action.payload
            }
        case CANCEL.SUCCESS:
            /** returns new empty object (it means that store.signingProcess is empty now) */
            return {}
        default:
            /** returns current state without any changes */
            return state
    }
}