import React, { PureComponent } from 'react'
import { Message } from 'semantic-ui-react'

class SubscriptionPlanInfo extends PureComponent {
    
    render() {
        return (
            <div className="ui padded segment">
                <Message warning>
                    <Message.Header>Document Not Authorized</Message.Header>
                    <p>Business doesn't have access to sign new waiver</p>
                </Message>
            </div>
        )
    }
}
export default SubscriptionPlanInfo
