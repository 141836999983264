import { put, takeLatest, select } from 'redux-saga/effects'
import moment from 'moment'
import { PERSON_INFO, SIGNING } from '../constants'
import { push } from 'react-router-redux'
import uuid from 'uuid/v4'

/**
 * Transformation of the object of persons
 * worker Saga: will be fired on PERSON_INFO.REQUEST
 *
 * @generator
 * @function informationComplete
 * @param {Object} action - action type and payload
 * @param {string} action.type - PERSON_INFO.REQUEST
 * @param {object} action.payload.location @see {@link https://reacttraining.com/react-router/core/api/Route/location-object}
 * @param {string} action.payload.url - original url
 * @param {string} action.payload.signing - type of person
 * @param {Object} action.payload.data - information about persons
 *
 * @see {@link https://github.com/redux-saga/redux-saga#sagasjs}
 *
 */

function* informationComplete(action) {
    const state = yield select()
    const personsData = {}
    const { url, signing, data, location } = action.payload
    const eventData = {}

    if (signing !== 'minors') {
        personsData.adult = data.adult
        const phoneType = personsData?.adult?.Phone?.phoneType
        if ('Mobile' !== phoneType) { personsData.adult.sendSms = false; }
        personsData.adult['Date of Birth'] = data.adult['Date of Birth'] ? moment(data.adult['Date of Birth']).format('MM/DD/YYYY') : null
    }
    if (signing === 'minors') {
        personsData.guardian = data.guardian
        const phoneType = personsData?.guardian?.Phone?.phoneType
        if ('Mobile' !== phoneType) { personsData.guardian.sendSms = false; }
        personsData.guardian['Date of Birth'] = data.guardian['Date of Birth'] ? moment(data.guardian['Date of Birth']).format('MM/DD/YYYY') : null
    }
    if (signing !== 'myself') {
        personsData.minors = data.minors
        personsData.minors.forEach(minor => minor['Date of Birth'] = minor['Date of Birth'] ? moment(minor['Date of Birth']).format('MM/DD/YYYY') : null)
    }

    let personInfo = []
    Object.keys(personsData).forEach((key, idx) => {
        if (key === 'adult') {
            personInfo.push({
                checkId: uuid(),
                isAdult: true,
                signedWaivers: {},
                ...personsData[key]
            })
        }
        else if (key === 'guardian') {
            personInfo.push({
                checkId: uuid(),
                isGuardian: true,
                ...personsData[key]
            })
        }
        else if (key === 'minors') {
            personsData[key].forEach(person => {
                person.checkId = uuid()
                person.isAdult = false
                person.signedWaivers = {}
                return person
            })
            personInfo = [...personInfo, ...personsData[key]]
        }
    })

    if (state.signingProcess.eventName) {
        eventData.eventName = state.signingProcess.eventName
    } else if (data.event && data.event.eventName) {
        eventData.eventName = data.event.eventName
    }
    if (state.signingProcess.eventDate) {
        eventData.eventDate = state.signingProcess.eventDate
    } else if (data.event && data.event.eventDate) {
        eventData.eventDate = data.event.eventDate
    }
    state.events.forEach(event => {
        if (event.name === eventData.eventName) {
            eventData.eventId = event._id
        }
    })

    yield put({
        type: SIGNING.EVENT_FOR_SIGNING.SET,
        payload: eventData
    })
    yield put({
        type: PERSON_INFO.SUCCESS,
        payload: personInfo
    })

    const searchParams = location.search

    yield put(push(`${url}/documentsSignature${searchParams}`))
}
/**
 *
 * Starts informationComplete on each dispatched PERSON_INFO.REQUEST action.
 *
 * We can use takeEvery or takeLatest
 *  takeEvery - Allows concurrent fetches of user
 *  takeLatest - Does not allow concurrent fetches
 *
 * @generator
 * @function collectParticipantInfo
 * @see {@link https://github.com/redux-saga/redux-saga#sagasjs}
 *
 */

export function* collectParticipantInfo() {
    yield [
        takeLatest(PERSON_INFO.REQUEST, informationComplete)
    ]
}