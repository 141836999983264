import { combineReducers } from 'redux'
import { UI } from '../constants'

/**
 * Set ui.errorPopup's data into store.
 *
 * @function errorPopup
 *
 * @param {Object} state - Current value of the ui.errorPopup field of the store (default value is an empty string)
 *
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of ui.errorPopup
 *
 */

export const errorPopup = (state = '', action) => {
    switch (action.type) {
        case UI.ERROR.SUCCESS:
            return action.payload
        case UI.ERROR.FAIL:
            return ''
        default:
            return state
    }
}

/**
 * Set ui.confirmationModal's data into store.
 *
 * @function confirmationModal
 *
 * @param {Object} state - Current value of the ui.confirmationModal field of the store (default value is an empty string)
 *
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of ui.confirmationModal
 *
 */

export const confirmationModal = (state = false, action) => {
    switch (action.type) {
        case UI.MODAL.SUCCESS:
            return action.payload
        case UI.MODAL.FAIL:
            return false
        default:
            return state
    }
}

/**
 * Set ui.confirmationModal's data into store.
 *
 * @function combineReducers
 *
 * @param {Object} - Object with reducers, which should be combined
 *
 * @returns {Object} New reducer which consists of reducers which were send as params
 *
 * For visual presentation:
 * State is an object with some fields - { ... }
 *
 * Without combineReducer we will have state like this -
 * {
 *  ...
 *  ...
 *  errorPopup: "",
 *  confirmationModal: ""
 * }
 *
 * With combineReducer state should be like this -
 * {
 *  ...
 *  ...
 *  ui: {
 *     errorPopup: "",
 *     confirmationModal: ""
 *   }
 * }
 *
 * @see {@link https://redux.js.org/api-reference/combinereducers}
 */

export default combineReducers({
    errorPopup,
    confirmationModal
})