import { put, takeLatest } from 'redux-saga/effects'
import { UI } from '../constants'

/**
 * 
 * Open confirmation modal window when user want to edit information about participant
 * 
 * @generator
 * @function confirmationModal
 * @param {object} action.type - UI.MODAL.REQUEST
 * @param {Object} action.payload.location @see {@link https://reacttraining.com/react-router/core/api/Route/location-object}
 * 
 */

function* confirmationModal(action) {
    yield put({
        type: UI.MODAL.SUCCESS,
        payload: action.payload
    })
}

/**
 * 
 * Starts confirmationModal on each dispatched UI.MODAL.REQUEST action.
 * 
 * We can use takeEvery or takeLatest
 *  takeEvery - Allows concurrent fetches of user
 *  takeLatest - Does not allow concurrent fetches
 * 
 * @generator
 * @function ui
 * @see {@link https://github.com/redux-saga/redux-saga#sagasjs}
 * 
 */

export function* ui() {
    yield [
        takeLatest(UI.MODAL.REQUEST, confirmationModal)
    ]
}