import fields from '../../multilingualism/fields'

export default (values, props) => {
    const { match: { params: { who } }, documentForSigning } = props
    if (!documentForSigning) return

    const errors = {}

    if (who !== 'minors' && !values.electronicSignature) {
        errors.electronicSignature = 'Signature must be applied to continue'
    }
    if (who !== 'myself' && !values.electronicSignature) {
        errors.electronicSignature = 'Signature must be applied to continue'
    }
    if (who !== 'myself' && !values.guardianSignature) {
        errors.guardianSignature = 'Signature must be applied to continue'
    }
    documentForSigning.initials && documentForSigning.initials.forEach((initial, idx) => {
        if (!values.initials || !values.initials[idx]) {
            if (!errors.initials) {
                errors.initials = []
            }
            if (initial.required) {
                errors.initials[idx] = fields.INITIAL_REQUIRED
            }
            else if (initial.acceptDecline) {
                errors.initials[idx] = fields.ACCEPT_DECLINE_REQUIRED
            }
        }
    })

    return errors
}
