import React from 'react'
import { Form, Label, Message, Header, Checkbox, Grid, List } from 'semantic-ui-react'
import getTranslator from '../../multilingualism/getTranslator'
import fields from '../../multilingualism/fields'

export default ({
    input,
    apply,
    body,
    signature,
    meta: { touched, error },
    languageSettings,
    waiverLanguage
}) => {
    const translate = getTranslator(languageSettings, waiverLanguage)
    return (<Form.Field>

        <Message info={!apply} id="special-message" positive={apply} style={{display: 'block'}}>
            <Grid verticalAlign="middle" columns="equal" stackable={true}>
                <Grid.Row className="padding-none">
                    <Grid.Column className="padding">
                        <List className="padding-none">
                            <List.Item>
                                {body}
                            </List.Item>
                        </List>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="padding-none">
                <Grid.Column  className="padding-none-left special-button">
                        <Checkbox
                            className="initial link"
                            type="checkbox"
                            onChange={(e, data) => {
                                input.onChange(data.checked)
                                input.onBlur(undefined, true)
                            }}
                            label={
                                apply ?
                                    <Header as='p' className="signature link">{signature}</Header>
                                    :
                                    <label
                                        className="not-display-checkbox">{translate(fields.INITIAL)}</label>
                            }/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Message>
        {touched && (error && <Label pointing basic color='red'> {translate(error)} </Label>)}
    </Form.Field>)
}
