import { WAIVER_LIST, CANCEL } from '../constants'

/**
 * Set waiverList's data into store.
 *
 * @function waiverList
 *
 * @param {Object} state - Current value of the waiverList field of the store (default value is an empty array)
 *
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of waiverList
 *
 */

export const waiverList = (state = [], action) => {
    switch (action.type) {
        case WAIVER_LIST.SUCCESS:
            return action.payload
        case WAIVER_LIST.FAIL:
            return []
        case CANCEL.SUCCESS:
            return []
        default:
            return state
    }
}