import { SIGNING, CANCEL } from '../constants'

/**
 * Set revokeSignedWaiver's data into store.
 *
 * @function revokeSignedWaiver
 * This reducer processes one of defined actions' types:
 * [SIGNING.REVOKE.SUCCESS, SIGNING.REVOKE.FAIL, CANCEL.SUCCESS].
 * In any other case it returns the state which it got as a first param.
 *
 * On SIGNING.REVOKE.SUCCESS action the function sets revokeSignedWaiver's data into store
 * On CANCEL.SUCCESS action the function clears revokeSignedWaiver and sets empty array into store
 * On SIGNING.REVOKE.FAIL action the function returns current value of revokeSignedWaiver
 *
 * @param {Object} state - Current value of the revokeSignedWaiver field of the store (default value is an empty array)
 *
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of revokeSignedWaiver
 *
 */

export const revokeSignedWaiver = (state = [], action) => {
    switch (action.type) {
        case SIGNING.REVOKE.SUCCESS:
            return action.payload
        case CANCEL.SUCCESS:
            return []
        case SIGNING.REVOKE.FAIL:
        default:
            return state
    }
}