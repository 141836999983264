import { FORM_FIELDS, CANCEL, EVENTS } from '../constants'

/**
 * Set formFields' data into store.
 *
 * This reducer processes one of defined actions' types:
 * [FORM_FIELDS.SUCCESS, FORM_FIELDS.FAIL, EVENTS.REGISTER_EVENT_DATE_FIELD.REQUEST, EVENTS.REMOVE_EVENT_DATE_FIELD.REQUEST, CANCEL.SUCCESS].
 * In any other case it returns the state which it got as a first param.
 *
 * @function formFields
 *
 * @param {Object} state - Current value of the formFields field of the store (default value is
 * {
 *   standard: [],
 *   custom: [],
 *   event: []
 * })
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {{standard: Array, custom: Array, event: Array}}
 */

export const formFields = (state = {
    standard: [],
    custom: [],
    event: []
}, action) => {
    switch (action.type) {
        case FORM_FIELDS.SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case FORM_FIELDS.FAIL:
            return {
                standard: [],
                custom: [],
                event: []
            }
        case EVENTS.REGISTER_EVENT_DATE_FIELD.REQUEST:
            return {
                ...state,
                event: [{ name: 'eventDate', required: true }]
            }
        case EVENTS.REMOVE_EVENT_DATE_FIELD.REQUEST:
            return {
                ...state,
                event: []
            }
        case CANCEL.SUCCESS:
            return {
                standard: [],
                custom: [],
                event: []
            }
        default:
            return state
    }
}