export class ApiCallTransformer {
    
    constructor (data) {
        this.data = data || {}
    }

    toZippopotamusResponse () {
        const response = { ...this.data } 
        const emptyResponse = [{'place name': '', 'state': '', 'state abbreviation': '' }] 

        response.places = (this.data.records && this.data.records.length)? this.data.records : emptyResponse
        if (!response.places.length || !response.places[0]) return response
        if (!response.places[0].fields) return response

        response.places[0]['place name'] = this.data.records[0].fields.place_name
        response.places[0]['state abbreviation'] = this.data.records[0].fields.admin_code1
        response.places[0]['state'] = this.data.records[0].fields.admin_name1

        return response
    }
}
