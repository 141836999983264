import { fork } from 'redux-saga/effects'
import { additional } from './additional'
import { collectParticipantInfo } from './collectParticipantInfo'
import { participantOptions } from './participantOptions'
import { selectParticipant } from './selectParticipant'
import { signedWaiver } from './signedWaiver'
import { ui } from './ui'
import {waiverLanguage} from "./waiverLanguage";

/**
 * 
 * This Saga yields an array with the results of calling our sagas: 
 *      additional,
 *      collectParticipantInfo,
 *      participantOptions,
 *      selectParticipant,
 *      signedWaiver and ui. 
 * This means the all resulting Generators will be started in parallel. 
 * Now we only have to invoke sagaMiddleware.run on the root Saga in /src/store/index.js
 * 
 * @generator
 * @function rootSaga
 * @see {@link https://redux-saga.js.org/docs/introduction/BeginnerTutorial.html}
 *  
 */

export function* rootSaga() {
    yield [
        fork(additional),
        fork(collectParticipantInfo),
        fork(participantOptions),
        fork(selectParticipant),
        fork(signedWaiver),
        fork(ui),
        fork(waiverLanguage)
    ]
}
