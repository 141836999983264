import React, { Component } from 'react'
import { Header as SemanticHeader, Image } from 'semantic-ui-react'
import {connect} from 'react-redux'

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = { isLoadImageError: false };
    }

    render() {
        const { businessEntity} = this.props
        let backgroundImage = {}

        if(businessEntity && businessEntity.websiteSettings){

            if(businessEntity.websiteSettings.wsHeaderImage && businessEntity.websiteSettings.wsHeaderImage.src){
                backgroundImage = {
                    backgroundImage: `url("${businessEntity.websiteSettings.wsHeaderImage.src}")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%'
                }
            }

            if(businessEntity.websiteSettings.wsBackgroundColor){
                document.body.style.backgroundColor = businessEntity.websiteSettings.wsBackgroundColor
            }
        }

        return (
            <SemanticHeader id="imageContainer" className="centered" style={backgroundImage} >
                <SemanticHeader>
                    <div className="ui vertical segment padding-none">
                        { businessEntity ? (businessEntity.images.logoSrc ?
                            <Image centered className='margin-bottom' id='logo' src={businessEntity.images.logoSrc} onError={(event) => { event.target.style.display = 'none'; this.setState({ isLoadImageError: true}) } }/> :
                            <div className="ui large header padding"
                                style={{color: (businessEntity.websiteSettings || {}).primaryColor || '#1c252f'}}>
                                {businessEntity.name}
                            </div>) : ''
                        }
                        { businessEntity && this.state.isLoadImageError?
                            <div className="ui large header padding"
                                 style={{color: (businessEntity.websiteSettings || {}).primaryColor || '#1c252f'}}>
                                {businessEntity.name}
                            </div> : ''
                        }
                    </div>
                </SemanticHeader>
            </SemanticHeader>
        )
    }
}

export default connect(
    state => ({
        businessEntity: state.additionalInformation.businessEntity
    })
)(Header)

