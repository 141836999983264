import React, {Component} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import {Field, FormSection } from 'redux-form'
import { Form, Label } from 'semantic-ui-react'
import getTranslator from '../../multilingualism/getTranslator'
import fields from '../../multilingualism/fields'

class BirthDateField extends Component {
    constructor(props){
        super(props)

        this.state = {
            datesPrefilled:  false
        }
    }

    getDateParam(){
        const { adultAge, id } = this.props
        const searchParams = new URLSearchParams(document.location.search)
        const dateString = searchParams.get('dateOfBirth')

        let month = null
        let day = null
        let year = null

        if(dateString && !id.includes('minor')) {
            const birthDate = new Date(dateString)
            const leapDays = Math.floor(adultAge/4) +1
            const adultAgeInMillis = (adultAge * 365 * 24 * 60 * 60 * 1000) + (leapDays * 24 * 60 * 60 * 1000)
            const today = new Date()

            month = birthDate.getMonth()
            day = birthDate.getDate()

            //make sure the prefilled data will result in an adult age.
            if(birthDate.getTime() + adultAgeInMillis < today.getTime()) {
                year = birthDate.getFullYear()
            }

        }

        return {month, day, year}
    }

    shouldComponentUpdate(nextProps) {
        const { formValues, formName, minorIdx } = this.props
        const person = formName.indexOf('[') !== -1 ? formName.slice(0, formName.indexOf('[')) : formName

        const newFormValues = nextProps.formValues
        const newFormName = nextProps.formName
        const newMinorIdx = nextProps.minorIdx
        const newPerson = newFormName.indexOf('[') !== -1 ? newFormName.slice(0, newFormName.indexOf('[')) : newFormName
        if (this.props.languageSettings.current !== nextProps.languageSettings.current) {
            return true
        }
        if (minorIdx) {
            if (formValues[person][minorIdx] && formValues[person][minorIdx]['Date of Birth'] && newFormValues[person][minorIdx] && newFormValues[person][minorIdx]['Date of Birth'] ) {
                return !(formValues[person][minorIdx]['Date of Birth'].month === newFormValues[newPerson][newMinorIdx]['Date of Birth'].month &&
                    formValues[person][minorIdx]['Date of Birth'].year === newFormValues[newPerson][newMinorIdx]['Date of Birth'].year)
            } else {
                return false
            }
        } else {
            if (formValues[person] && formValues[person]['Date of Birth'] && newFormValues[newPerson]['Date of Birth']) {
                return !(formValues[person]['Date of Birth'].month === newFormValues[newPerson]['Date of Birth'].month &&
                    formValues[person]['Date of Birth'].year === newFormValues[newPerson]['Date of Birth'].year)
            } else {
                return false
            }
        }
    }

    getDaysArray() {
        const {formValues, formName, minorIdx, personInfo} = this.props
        const result = []
        const person =  formName.indexOf('[') !== -1 ? formName.slice(0, formName.indexOf('[')) : formName
        let month = 0
        let year = moment().format('YYYY')
        if (formValues) {
            if (person === 'minors') {
                if (formValues[person] && formValues[person][minorIdx] && formValues[person][minorIdx]['Date of Birth']) {
                    month = formValues[person][minorIdx]['Date of Birth'].month || 0
                    year = formValues[person][minorIdx]['Date of Birth'].year || moment().format('YYYY')
                }
            } else {
                if (formValues[person] && formValues[person]['Date of Birth']) {
                    month = formValues[person]['Date of Birth'].month || 0
                    year = formValues[person]['Date of Birth'].year || moment().format('YYYY')
                }
            }
        } else if (personInfo.length) {
            if (person === 'minors') {
                month = personInfo[minorIdx + 1]['Date of Birth'].split('/')[0]
                year = personInfo[minorIdx + 1]['Date of Birth'].split('/')[2]
            } else {
                month = personInfo[0]['Date of Birth'].split('/')[0]
                year = personInfo[0]['Date of Birth'].split('/')[2]
            }
        }
        while (result.length !== new Date(year, (month + 1), 0).getDate() ) {
            result.push(result.length + 1)
        }

        return [...result]
    }

    getYearsArray() {
        const { signing, adultAge } = this.props
        let start, end
        const result = []
        if (signing !== 'minors') {
            start = moment().add(-adultAge, 'years').year()
            end = moment().add(-99, 'years').year()
        } else {
            start = moment().year()
            end = moment().add(-adultAge, 'years').year()
        }
        while(start >= end) {
            result.push(start--)
        }
        return [...result]
    }

    renderSelectField(type, dateValue) {
        const {languageSettings, waiverLanguage} = this.props
        const translate = getTranslator(languageSettings, waiverLanguage)
        let options = []
        if (type === fields.MONTH) {
            options = translate('MONTHS')
        }
        else if (type === fields.DAY) {
            options = this.getDaysArray()
        } else {
            options = this.getYearsArray()
        }

        return ({ id, input, meta: { touched, error } }) =>
            <Form.Field error={!!touched && !!error}>
                <div className={!!touched && !!error ? 'error' : ''}>
                    <select
                        {...input}
                        id={id}
                        onChange={({target}) => {
                            input.onChange(parseInt(target.value, 10))
                        }}
                        onBlur={({target}) => {
                            input.onBlur(target.value ? parseInt(target.value, 10) : '')
                        }}
                        className='ui dropdown'
                    >
                        <option key={translate(type)} value="" disabled selected>{translate(type)}</option>
                        {
                            [
                                ...options.map((option, index) => {
                                    const key = type === fields.MONTH ? index : option
                                    return {
                                        key: key,
                                        text: option,
                                        value: key
                                    }
                                })].map(option => (<option key={option.key} value={option.value}>{option.text}</option>))
                        }
                    </select>
                </div>
                {touched && (error && <Label pointing basic color='red' id={`error_${id}`}> {translate(error)} </Label>)}
                {(dateValue !== null) ? this.dumbSingleton(dateValue, type, input) : ''}
            </Form.Field>
    }

    /*
    a dumb block of code with a check to make sure each segment of the date of birth component is only pre-loaded once
    Without this check the dob dropdowns would automatically be set back to the data in the query params each time the
    form rendered.
    After, a boolean is set in the state to ensure that only the first render results in the value being set.
    Also, yeah, not actually a singleton, but it's supposed to be only run once. So, whatever.
     */
    dumbSingleton(dateValue,type,input){
        if(input.value === '' && dateValue !== null){
            input.onChange(dateValue)
        }
    }

    render() {
        const {fieldName, required, label, adultAge, id} = this.props
        const {languageSettings, signing, waiverLanguage} = this.props
        const translate = getTranslator(languageSettings, waiverLanguage)
        const {month, day, year} = this.getDateParam()

        console.log(`month: ${month}`)

        return (
            <FormSection name={fieldName}>
                <Field
                    name='birthDate'
                    required={required}
                    component={props => {
                        const { touched, error } = props.meta
                        return (<Form.Field required={required}>
                            <label htmlFor={id}>{label}</label>
                            <Form.Group>
                                <Form.Field width={11}>
                                    <Field
                                        id={signing + `Month`}
                                        name={`month`}
                                        component={this.renderSelectField(fields.MONTH, month)}
                                    />
                                </Form.Field>
                                <Form.Field width={11}>
                                    <Field
                                        id={signing + `Day`}
                                        name={`day`}
                                        component={this.renderSelectField(fields.DAY, day)}
                                    />
                                </Form.Field>
                                <Form.Field width={11}>
                                    <Field
                                        id={signing + `Year`}
                                        name={`year`}
                                        component={this.renderSelectField(fields.YEAR, year)}
                                    />
                                </Form.Field>
                            </Form.Group>
                            {touched && (error && <Label pointing basic color='red' id={`error_birthDate`}> {translate(error, {age: adultAge})} </Label>)}
                        </Form.Field>)
                    }}
                />
            </FormSection>)
    }
}

export default connect(
    state => ({
        languageSettings: state.languageSettings,
        formValues: state.form.CollectParticipantInfoForm.values,
        personInfo: state.personInfo,
        waiverLanguage: state.waiverLanguage
    })
)(BirthDateField )
