import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List } from 'semantic-ui-react'

class PersonDocuments extends Component {
    getAvailableLanguage(waiver) {
        const {
            languageSettings
        } = this.props

        let documentLanguage;
        for(let key in waiver.showDuringSigning){
            if(waiver.showDuringSigning[key]){
                documentLanguage = key
                break;
            }
        }
        let isCurrentLanguageAvailable = false

        if (waiver[languageSettings.current].content) {
            if (waiver.initials.length) {
                waiver.initials.forEach(initial => {
                    if (initial[languageSettings.current].content) {
                        isCurrentLanguageAvailable = true
                    } else {
                        isCurrentLanguageAvailable = false
                    }
                })
            } else {
                isCurrentLanguageAvailable = true
            }
        }

        return isCurrentLanguageAvailable ? languageSettings.current : documentLanguage
    }

    renderBlock = waiver => {
        const { person, partialNotification } = this.props
        const waiverLanguage = this.getAvailableLanguage(waiver)
        if (!partialNotification && person.signedWaivers[waiver._id] && person.signedWaivers[waiver._id].completedDate) {
            return <List.Item as='li' className="padding">{waiver.name[waiverLanguage]}</List.Item>
        }
        return <List.Item as='li' className="padding">
            {waiver.name[waiverLanguage]}
        </List.Item>
    }

    render() {
        const { waiverList } = this.props
        return (<div>
                {waiverList.map((waiver, idx) =>

                    <div key={idx}>
                    {this.renderBlock(waiver)}
                </div>
                )}
            </div>
        )
    }
}

export default connect(
    state => ({
        languageSettings: state.languageSettings
    })
)(PersonDocuments)
