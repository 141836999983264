import { createStore, applyMiddleware, combineReducers } from 'redux'
import reducers from '../reducers'
import { createBrowserHistory } from 'history'
import { routerReducer, routerMiddleware } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import { composeWithDevTools } from 'redux-devtools-extension'

import createSagaMiddleware from 'redux-saga'
/**
 * Root Saga is the bootstrap for redux-saga which needs to be installed inside a Redux store in order to run.
 * @todo check this @max @alex
 * @name rootSaga
 */
import { rootSaga } from '../sagas/index'

/**
 * Redux App Store that sits at the top of the application. The store is made up of reducers and dev tools.
 * @module store/index
 * @see {@link https://redux.js.org/api-reference/combinereducers}
 */
export default function configureStore () {

    const reducer = combineReducers({
        ...reducers,
        router: routerReducer,
        form: formReducer
    })
    const history = createBrowserHistory()
    const historyMiddleware = routerMiddleware(history)
    const sagaMiddleware = createSagaMiddleware()
    const store = createStore(
        reducer,
        composeWithDevTools(applyMiddleware(historyMiddleware, sagaMiddleware))
    )

    /**
     * Something saga
     * @var sagaMiddleware
     */
    sagaMiddleware.run(rootSaga)
    return {
        ...store,
        history
    }
}
