import { participantOptions } from './participantOptions'
import { personInfo } from './collectParticipantInfo'
import { loading } from './loading'
import { personsForSigning } from './personsForSigning'
import { selectParticipant } from './selectParticipant'
import additionalInformation from './additional'
import { waiverList } from './waiverList'
import { formFields } from './formFields'
import { signingProcess } from './signingProcess'
import ui from './ui'
import { documentForSigning } from './documentForSigning'
import { signingSession } from './signingSession'
import { events } from './events'
import { revokeSignedWaiver } from './revokeSignedWaiver'
import languageSettings from './languageSettings'
import waiverLanguage from './waiverLanguage'

/**
 * Returns one object which is collected all reducers of the application
 */

export default {
    participantOptions,
    personInfo,
    loading,
    selectParticipant,
    waiverList,
    formFields,
    signingProcess,
    additionalInformation,
    ui,
    personsForSigning,
    documentForSigning,
    signingSession,
    events,
    revokeSignedWaiver,
    languageSettings,
    waiverLanguage
}
