import React, { Component } from 'react'
import { Form, Label } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { createAction } from '../../sagas/createAction'
import { ADDITIONAL_INFORMATION } from '../../constants'
import { change } from 'redux-form'
import getTranslator from '../../multilingualism/getTranslator'

class countrySelectField extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const {
            required,
            input,
            options,
            placeholder,
            label,
            getPostalCodeData,
            meta: { touched, error },
            change,
            languageSettings,
            waiverLanguage,
            id
        } = this.props

        if (typeof input.value === 'object') {
            input.value = JSON.stringify(input.value)
        }

        const translate = getTranslator(languageSettings, waiverLanguage)

        return (
            <Form.Field required={required}>
                <label htmlFor={id}>{label}</label>
                <div className={`field ${!!touched && !!error ? 'error' : ''}`}>
                    <select
                        {...input}
                        id={id}
                        placeholder={placeholder || label}
                        onChange={({target}) => {
                            input.onChange(target.value)

                            if (input.value !== target.value) {
                                change('CollectParticipantInfoForm', `${target.name.split('.')[0]}.State`, '')
                            }

                            getPostalCodeData(target.name.split('.')[0])
                        }}
                        required={required}
                        className='ui dropdown'
                    >
                        {
                            options.map(option => (<option key={option.key} value={option.value}>{option.text}</option>))
                        }
                    </select>
                </div>
                {touched && (error && <Label pointing basic color='red' id={`error_${id}`}> {translate(error)} </Label>)}
            </Form.Field>
        )
    }
}

export default connect(
    state => ({
        languageSettings: state.languageSettings,
        waiverLanguage: state.waiverLanguage
    }),
    {
        getPostalCodeData: createAction(ADDITIONAL_INFORMATION.POSTAL_CODE),
        change
    }
)(countrySelectField)
