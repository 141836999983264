import {call, put, select, takeLatest} from 'redux-saga/effects'
import {PARTICIPANT_OPTIONS, UI, SIGNING, ADDITIONAL_INFORMATION, LANGUAGE_SETTINGS, LANGUAGE} from '../constants'
import {fetchGet, fetchPost} from '../apiCalls/index'

/**
 * 
 *  Load Participant Options and Business Entity: { _id, name, images.logoSrc } for list of documents
 * 
 * @generator
 * @function loadParticipant
 * @param {string} ids - Id of documents
 * 
 */

function* loadParticipant(ids, entityId) {
    const state = yield select()

    let response

    if(entityId){
        response = yield call(fetchGet, `document/e/${entityId}/${ids.split(',')}`)
    }
    else{
        response = yield call(fetchGet, `document/${ids.split(',')}`)
    }

    if(response.success) {
        yield put({
            type: LANGUAGE_SETTINGS.SET,
            payload: state.languageSettings.current ? state.languageSettings :  response.payload.languageSettings
        })
        yield put({
            type: PARTICIPANT_OPTIONS.SUCCESS,
            payload: response.payload.participantOptions
        })
        yield put({
            type: ADDITIONAL_INFORMATION.BUSINESS_ENTITY.SUCCESS,
            payload: response.payload.businessEntity
        })
    } else {
        yield put({
            type: UI.ERROR.SUCCESS,
            payload: response.message
        })
    }
}

/**
 *  Load expireAt Date, CallbackUrl, returnUrl and referenceId
 * 
 * @generator
 * @function loadSigningSession
 * @param {string} type - endpoint type
 * @param {strinf} ids - Session Id
 * 
 */

function* loadSigningSession(type, ids, entityId) {
    const response = yield call(fetchGet, `${type}/${ids}`)
    if (response.success) {
        yield loadParticipant(response.payload.documentIdList.join(','), entityId)
        yield put({
            type: SIGNING.SESSION.SUCCESS,
            payload: response.payload
        })
    } else {
        yield put({
            type: SIGNING.SESSION.FAIL
        })
        yield put({
            type: UI.ERROR.SUCCESS,
            payload: response.message
        })
    }
    //yield loadParticipant(response.payload.documentIdList.join(''))
}

/**
 * 
 * Load Participant Options, Signed Waiver List , Additional Information and Signing Process Information
 * 
 * @generator
 * @function loadRevokeSignedWaiver
 * @param {Object} location @see {@link https://reacttraining.com/react-router/core/api/Route/location-object}
 * @param {string} type - endpoint type
 * @param {string} ids - signedWaiverIds
 * 
 */

function* loadRevokeSignedWaiver(location, type, ids, entityId) {
    const response = yield call(fetchGet, `${type}/${ids}`)

    if(response.success) {
        yield put({
            type: PARTICIPANT_OPTIONS.SUCCESS,
            payload: response.payload.participantOptions
        })
        yield put({
            type: SIGNING.REVOKE.SUCCESS,
            payload: response.payload.signedWaiverList
        })
        yield put({
            type: ADDITIONAL_INFORMATION.BUSINESS_ENTITY.SUCCESS,
            payload: response.payload.businessEntity
        })

        let beginSigningUrl = `/${type}/${ids}${location.search}`
        if(entityId){
            beginSigningUrl = `/e/${entityId}/${type}/${ids}${location.search}`
        }

        let returnUrl = `${window.location.origin}/${type}/${ids}${location.search}`
        if(entityId){
            returnUrl = `${window.location.origin}/e/${entityId}/${type}/${ids}${location.search}`
        }

        const signingProcessInformation = {
            partialNotification: true,
            returnUrl: returnUrl,
            beginSigningUrl: beginSigningUrl
        }

        yield put({
            type: SIGNING.PROCESS.SUCCESS,
            payload: signingProcessInformation
        })

    } else {
        yield put({
            type: SIGNING.REVOKE.FAIL
        })
        yield put({
            type: UI.ERROR.SUCCESS,
            payload: response.message
        })
    }
}

/**
 * 
 * Load "Participant Options" for different endpoints
 * 
 * @generator
 * @function loadParticipantOptions
 * @param {object} action - { location, type, ids }
 * @param {Object} location @see {@link https://reacttraining.com/react-router/core/api/Route/location-object}
 * @param {string} type - endpoint type
 * @param {string} ids - session Id or Id of documents or signedWaiverIds
 * 
 * Id of documents for endpoint "/document/:documentIds"
 * Session Id for endpoint "/session/:sessionId"
 * Signed Waiver Ids for endpoint "/revoke/:signedWaiverIds"
 * 
 */

function* loadParticipantOptions(action) {
    const { location, type, ids, entityId } = action.payload
    if (type === 'doc') {
        yield loadParticipant(ids, entityId)

    } else if (type === 'revoke') {
        yield loadRevokeSignedWaiver(location, type, ids, entityId)
    } else {
        yield loadSigningSession(type, ids, entityId)
    }
}

/**
 *
 * Starts loadParticipantOptions on each dispatched PARTICIPANT_OPTIONS.REQUEST action.
 *
 * We can use takeEvery or takeLatest
 *  takeEvery - Allows concurrent fetches of user
 *  takeLatest - Does not allow concurrent fetches
 *
 * @generator
 * @function participantOptions
 * @see {@link https://github.com/redux-saga/redux-saga#sagasjs}
 *
 */

export function* participantOptions() {
    yield [
        takeLatest(PARTICIPANT_OPTIONS.REQUEST, loadParticipantOptions)
    ]
}
