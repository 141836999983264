import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Form, Label } from 'semantic-ui-react'
import getTranslator from '../../multilingualism/getTranslator'
import fields from '../../multilingualism/fields'

class RadioWithImage extends Component {
    render() {
        const { input, fieldName, label, meta: { touched, error }, img, languageSettings, waiverLanguage } = this.props
        const comment = label.slice(label.indexOf('('), label.indexOf(')') + 1)
        const labelText = label.split('(')[0]
        const translate = getTranslator(languageSettings, waiverLanguage)
        return (
            <Form.Field className="flexColumn">
                <Form.Radio id={fieldName} type='radio' {...input} className='unvisible noMargin' onChange={(param,data) => input.onChange(data.value)} />
                <label htmlFor={fieldName} className='flex flexColumn pointer' id={fieldName.replace(/ /g, '')}>
                    {img}
                    <label htmlFor={fieldName} className='centered pointer'><strong>{labelText}</strong></label>
                    <label htmlFor={fieldName} className='pointer smallText'>{comment}</label>
                    <div className="selectBtn" aria-label={`Select` + labelText}>{input.checked ? translate(fields.SELECTED).toUpperCase() : translate(fields.SELECT).toUpperCase()}</div>
                </label>
                {touched && (error && <Label pointing error={true}> {error} </Label>)}
            </Form.Field>
        )
    }
}

export default connect(
    state => ({
        languageSettings: state.languageSettings,
        waiverLanguage: state.waiverLanguage
    })
)(RadioWithImage)
