import React, {PureComponent} from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import renderHTML from 'react-render-html'
import validate from './validate'
import messageField from '../Custom/messageField'
import initialField from '../Custom/initialField'
import acceptDeclineField from '../Custom/acceptDeclineField'
import { Form, Header, List, Button, Segment, Message, Grid, Responsive } from 'semantic-ui-react'
import { createAction } from '../../sagas/createAction'
import { SIGNING, CANCEL, UI } from '../../constants'
import moment from 'moment'
import 'moment/locale/es'
import getTranslator from '../../multilingualism/getTranslator'
import fields from '../../multilingualism/fields'
import {push} from "react-router-redux";

class SigningDocument extends PureComponent {
    componentDidMount() {
        const { cancel, match, location, documentForSigning } = this.props
        if (!Object.keys(documentForSigning).length) {
            cancel({ match, location })
        }
        window.scrollTo(0, 0)

    }
    getAvailableLanguage() {
        const {
            documentForSigning,
            languageSettings
        } = this.props

        let documentLanguage;
        for(let key in documentForSigning.active){
            if(documentForSigning.showDuringSigning[key]){
                documentLanguage = key
                break;
            }
        }
        let isCurrentLanguageAvailable = false
        if (documentForSigning[languageSettings.current].content) {
            if (documentForSigning.initials.length) {
                documentForSigning.initials.forEach(initial => {
                    if (initial[languageSettings.current].content) {
                        isCurrentLanguageAvailable = true
                    } else {
                        isCurrentLanguageAvailable = false
                    }
                })
            } else {
                isCurrentLanguageAvailable = true
            }
        }

        return isCurrentLanguageAvailable ? languageSettings.current : documentLanguage
    }
    translate(field, params, smartCount) {

        const {waiverLanguage} = this.props

        const translate = getTranslator({current: this.getAvailableLanguage()}, waiverLanguage)

        return translate(field, params, smartCount)
    }

    getId = key => (key.slice(0,1).toLowerCase() + key.slice(1, key.length)).replace(/\W/g, '');

    handleEditInformation = () => {
        const { location, openConfirmationModal } = this.props
        openConfirmationModal({ location })
    }

    handleCancel = () => {
        const { signingProcess: { returnUrl }, cancel, match, location } = this.props
        const searchParams = new URLSearchParams(document.location.search)
        const returnUrlParam = searchParams.get('returnUrl')

        if(returnUrlParam){
            push(returnUrlParam)
        }

        returnUrl ? window.open(returnUrl, '_self') : cancel({match, location})
    }

    renderInfoContent = person => {
        const customFormFields = this.props.customFormFields
        return (<div>
        {Object.keys(person).map((key, idx) => {
            if (key === 'customFieldList' && person[key] && Object.keys(person[key]).length) {
                const customFormFieldsMap = {}
                customFormFields.forEach((field) => {
                    customFormFieldsMap[field.name] = field
                })
                const isMinor = !person.isAdult && !person.isGuardian;
                const isAdult = person.isAdult;
                const isGuardian = person.isGuardian;
                return Object.keys(person[key]).map((customKey, customIdx) => {
                    const customField = customFormFieldsMap[customKey]
                    if(customField) {
                        if((isAdult && customField.adultOption !== "hidden") || (isMinor && customField.minorOption !== "hidden")
                            || (isGuardian && customField.guardianOption !== "hidden")) {
                            return (<div key={customKey} id={this.getId(customKey)} className='fieldContainer'>
                                <div className='fieldLabel'>{customKey}: </div>
                            <div className='fieldContent'>
                                <label>{Array.isArray(person[key][customKey]) ? person[key][customKey].join(', ') : person[key][customKey]}</label>
                                </div>
                                </div>)
                        }
                    }
                    return null;
                })
            } else if(key === 'Organization' && person[key]) {
                return <div key={idx} id={this.getId(key)} className='fieldContainer'>
                    <div className='fieldLabel'>{this.translate(fields.ORGANIZATION)}: </div>
                    <div className='fieldContent'>
                        <label>{person[key]}</label>
                    </div>
                </div>
            }
            else if (key === 'Phone' && person[key] && person[key].phoneNumber && person[key].phoneNumber.replace(/-/g, '').trim().length) {
                return <div key={idx} id={this.getId(key)} className='fieldContainer'>
                    <div className='fieldLabel'>{this.translate(fields.PHONE)}: </div>
                    <div className='fieldContent'>
                    <label>{person[key].phoneNumber}</label>
                    </div>
                </div>
            }
            else if (key === 'Identification' && person[key] && person[key].type && person[key].number) {
                return <div key={idx} id={this.getId(key)} className='fieldContainer'>
                    <div className='fieldLabel'>{this.translate(fields.IDENTIFICATION)}: </div>
                    <div className='fieldContent'>
                        <label>{person[key].type} ({this.translate(fields.LAST_DIGITS)}: {person[key].number})</label>
                    </div>
                </div>
            }
            else if (key === 'Country' && person[key]) {
                return <div key={idx} id={this.getId(key)} className='fieldContainer'>
                    <div className='fieldLabel'>{this.translate(fields.COUNTRY)}: </div>
                    <div className='fieldContent'>
                        <label>{typeof person[key] === 'string' ? JSON.parse(person[key]).name : person[key].name}</label>
                    </div>
                </div>
            } else if (key === 'State' && person[key]) {
                let state
                try {
                    state = typeof person[key] === 'string' ? JSON.parse(person[key]).name : person[key].name
                } catch(e) {
                    state = person[key]
                }
                return <div key={idx} id={this.getId(key)} className='fieldContainer'>
                    <div className='fieldLabel'>{this.translate(fields.STATE)}: </div>
                    <div className='fieldContent'>
                        <label>{state}</label>
                    </div>
                </div>
            }
            else if (key === 'Gender') {
                return <div key={idx} id={this.getId(key)} className='fieldContainer'>
                    <div className='fieldLabel'>{this.translate(fields.GENDER)}: </div>
                    <div className='fieldContent'>
                        <label> {person[key][0]+person[key].slice(1).toLowerCase().replace('_', ' ')}</label>
                    </div>
                </div>
            }
            else if (key === 'Date of Birth') {
                moment.locale(this.props.languageSettings.current)
                const field = (
                    <div key={idx} id={this.getId(key)} className='fieldContainer'>
                        <div className='fieldLabel'>{this.translate(fields.BIRTH_DATE)}: </div>
                        <div className='fieldContent'>
                            <label>{moment(new Date(person[key])).format('ll')}</label>
                        </div>
                    </div>
                )
                return field
            }
            else if (key === 'Legal First Name' || key === 'Last Name' || key === 'Date of Birth' || key === 'Email' ||
                key === 'Street Address' || key === 'Postal Code' || key === 'City' || key === 'Middle Name'
            ) {
                let label = key
                if (key === 'Legal First Name') {
                    label = this.translate(fields.FIRST_NAME)
                } else if (key === 'Last Name') {
                    label = this.translate(fields.LAST_NAME)
                } else if (key === 'Date of Birth') {
                    label = this.translate(fields.BIRTH_DATE)
                } else if (key === 'Street Address') {
                    label = this.translate(fields.ADDRESS)
                } else if (key === 'Postal Code') {
                    label = this.translate(fields.POSTAL_CODE)
                } else if (key === 'City') {
                    label = this.translate(fields.CITY)
                } else if (key === 'Middle Name') {
                    label = this.translate(fields.MIDDLE_NAME)
                } else if (key === 'Email') {
                    label = this.translate(fields.EMAIL)
                }
                return (
                    <div key={idx} id={this.getId(key)} className='fieldContainer'>
                        <div id={this.getId(key)+'-label'} className='fieldLabel'>{label}: </div>
                        <div className='fieldContent'>
                            <label>{person[key]}</label>
                        </div>
                    </div>
                )
            }
            return null
        })}
    </div>)};

    renderAdultGuardianInfo = () => {
        const { personsForSigning,applyElectronicSignature,documentForSigning } = this.props
        const language = this.getAvailableLanguage()
        const person = personsForSigning.filter(person => person.isAdult)[0] || personsForSigning.filter(person => person.isGuardian)[0]

        return <div>
            <Segment id='participant'>
                <Grid>
                    <Grid.Column width={8}>
                        <Header id="participantHeader" as='h2'>
                            {person.isGuardian ? this.translate(fields.PARENT_HEADER) : this.translate(fields.PARTICIPANT_HEADER)}
                        </Header>
                    </Grid.Column>
                    <Grid.Column textAlign='right' width={8}>
                        {this.props.match.params.type !== 'session' ? <a className="ui link" onClick={this.handleEditInformation}><i className="ui blue edit icon"></i>{this.translate(fields.EDIT_INFORMATION)}</a>
                        : null }

                    </Grid.Column>
                </Grid>
                <div className="ui divider"></div>
                <div id="participantContentContainer" className="margin-segmant">
                    <Form.Field>
                        {this.renderInfoContent(person)}
                      <Field
                        apply={applyElectronicSignature}
                        title={documentForSigning.electronicSignatureTitle[language]}
                        body={renderHTML(documentForSigning.electronicSignature[language].content)}
                        signature={person['Legal First Name'].trim() + ' ' + person['Last Name'].trim()}
                        name="electronicSignature"
                        component={messageField}
                        nameBtn={this.translate(fields.APPLY_SIGNATURE)}
                        id="adultApplySignature"
                    />
                    </Form.Field>
                </div>
            </Segment>
        </div>
    }

    renderInitials = () => {
        const { applyInitials, personsForSigning, documentForSigning, languageSettings, waiverLanguage } = this.props
        const language = this.getAvailableLanguage()
        const person = personsForSigning.filter(person => person.isGuardian)[0] || personsForSigning.filter(person => person.isAdult)[0]
        return <Form.Field>
            <List>
                {documentForSigning.initials.map((initial, idx) => (initial.required || initial.acceptDecline) ?
                    <Field
                        key={idx}
                        apply={applyInitials[idx]}
                        body={renderHTML(initial[language].content)}
                        signature={(person['Legal First Name'] || ' ').trim()[0] + (person['Last Name'] || ' ').trim()[0]}
                        name={'initials['+idx+']'}
                        component={initial.required ? initialField : acceptDeclineField}
                        languageSettings={languageSettings}
                        waiverLanguage={waiverLanguage}
                    />
                    : <List.Item key={idx}>{renderHTML(initial[language].content)}</List.Item>)}
            </List>

        </Form.Field>
    }

    renderMinorsInfo = () => {
        const { personsForSigning, applyGuardianSignature, documentForSigning } = this.props
        const language = this.getAvailableLanguage()
        const persons = personsForSigning.filter(person => !person.isGuardian && !person.isAdult)
        const personGuardian = {...personsForSigning.filter(person => person.isGuardian)[0] || personsForSigning.filter(person => person.isAdult)[0]}
        if (persons.length) {
            return <Segment>
                <div className="margin-segmant">
                    <Form.Field>
                        {
                            persons.map((person, idx) => <div key={idx} id={idx ? 'minor'+(idx+1) : ''}>
                                    <Header as='h2' dividing>
                                        {this.translate(fields.MINOR_HEADER, {count: idx + 1})}
                                    </Header>
                                    {this.renderInfoContent(person)}
                                </div>
                            )
                        }
                    </Form.Field>
                    <Form.Field>
                        <Field
                            apply={applyGuardianSignature}
                            title={documentForSigning.guardianSignatureTitle[language]}
                            body={renderHTML(documentForSigning.guardianSignature[language].content)}
                            signature={personGuardian['Legal First Name'].trim() + ' ' + personGuardian['Last Name'].trim()}
                            name="guardianSignature"
                                component={messageField}
                                nameBtn={this.translate(fields.APPLY_SIGNATURE_OF_MINOR)}
                            id="guardianApplySignature"
                        />
                    </Form.Field>
                </div>
            </Segment>
        }
        return null
    }

    render() {
        const {
            handleSubmit,
            documentForSigning,
            loading,
            personInfo,
            submitFailed,
            applyGuardianSignature,
            applyElectronicSignature,
            personsForSigning
        } = this.props

        const persons = (personsForSigning || []).filter(person => !person.isGuardian && !person.isAdult)
        const canSubmit = persons.length ? applyGuardianSignature !== undefined && applyElectronicSignature !== undefined : applyElectronicSignature !== undefined
        if (!documentForSigning.initials)  return null

        let personEmail
        personInfo.forEach(person => {
            if(person.isAdult || person.isGuardian) {
                personEmail = person.Email
            }
        })
        const language = this.getAvailableLanguage()
        return (
            <Form onSubmit={handleSubmit} className="padding-top">
                <Responsive as={'segment'} padded='very' id="document-signing" minWidth={768}>
                    <Header id="documentName" as='h1'>{documentForSigning.name[language]}</Header>
                    <Form.Field>
                        {renderHTML(documentForSigning[language].content)}
                    </Form.Field>
                    {documentForSigning.initials.length ? this.renderInitials() : ''}
                    <div className="margin-segmant">
                        {this.renderAdultGuardianInfo()}
                    </div>
                    {this.renderMinorsInfo()}
                    <Segment basic>
                        <Header as='h3' content={this.translate(fields.CERTIFY_INFORMATION)} subheader={this.translate(fields.EMAILED_TO, {email: personEmail})} />
                        {submitFailed && <Message
                            negative
                            content={this.translate(fields.SCROLL_UP)}
                        />}
                        <Button id="agreeAndSubmitButton"primary size="big" type="submit" disabled={loading || !canSubmit} loading={loading}>{this.translate(fields.AGREE_SUBMIT)}</Button>
                        <Button id="cancelButton" secondary size="big" type="button" className="margin-left" onClick={this.handleCancel} disabled={loading}>{this.translate(fields.CANCEL)}</Button>
                    </Segment>
                </Responsive>
                <Responsive as={'segment'} basic padded='very' id="document-signing" maxWidth={767}>
                    <Header id="documentName" as='h1'>{documentForSigning.name[language]}</Header>
                    <Form.Field>
                        {renderHTML(documentForSigning[language].content)}
                    </Form.Field>
                    {documentForSigning.initials.length ? this.renderInitials() : ''}
                    <div className="margin-segmant">
                        {this.renderAdultGuardianInfo()}
                    </div>
                    {this.renderMinorsInfo()}
                    <Segment basic>
                        <Header as='h3' content={this.translate(fields.CERTIFY_INFORMATION)} subheader={this.translate(fields.EMAILED_TO, {email: personEmail})} />
                        {submitFailed && <Message
                            negative
                            content={this.translate(fields.SCROLL_UP)}
                        />}
                        <Button id="agreeAndSubmitButton"primary size="big" type="submit" disabled={loading || !canSubmit} loading={loading}>{this.translate(fields.AGREE_SUBMIT)}</Button>
                        <Button id="cancelButton" secondary size="big" type="button" onClick={this.handleCancel} disabled={loading}>{this.translate(fields.CANCEL)}</Button>
                    </Segment>
                </Responsive>
            </Form>
        )
    }
}

const handleSubmitForm = (data, dispatch, { match, submitDocument, documentForSigning, personsForSigning, signingProcess, personInfo, waiverList }) => {
    const searchParams = new URLSearchParams(document.location.search)
    const callbackUrl = searchParams.get('callbackUrl')

    submitDocument({ signingData: data, url: callbackUrl || match.url})
}

const SigningDocumentForm = reduxForm({
    form: 'SigningDocumentForm',
    onSubmit: handleSubmitForm,
    validate
})(SigningDocument)

const selector = formValueSelector('SigningDocumentForm')

export default connect(
    state => {
        const applyElectronicSignature = selector(state, 'electronicSignature')
        const applyGuardianSignature = selector(state, 'guardianSignature')
        const applyInitials = []
        state.documentForSigning.initials && state.documentForSigning.initials.forEach((initial, idx) => {
            if (initial.required || initial.acceptDecline) {
                applyInitials[idx] = selector(state, 'initials['+idx+']')
            }
        })
        return {
            applyElectronicSignature,
            applyGuardianSignature,
            applyInitials,
            documentForSigning: state.documentForSigning,
            personsForSigning: state.personsForSigning,
            personInfo: state.personInfo,
            signingProcess: state.signingProcess,
            loading: state.loading,
            waiverList: state.waiverList,
            businessEntity: state.additionalInformation.businessEntity,
            languageSettings: state.languageSettings,
            waiverLanguage: state.waiverLanguage,
            customFormFields: state.formFields.custom
        }
    },
    {
        submitDocument: createAction(SIGNING.DOCUMENT.SINGLE),
        cancel: createAction(CANCEL),
        backToEditInformation: createAction(SIGNING.INFO.CLEAR),
        openConfirmationModal: createAction(UI.MODAL)
    }
)(SigningDocumentForm)
