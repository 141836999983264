import { EVENTS, CANCEL } from '../constants'

/**
 * Set events's data into store.
 *
 * This reducer processes one of defined actions' types:
 * [EVENTS.SET, EVENTS.CLEAR, CANCEL.SUCCESS].
 * In any other case it returns the state which it got as a first param.
 *
 * On EVENTS.SET action the function sets events' data into store
 * On EVENTS.CLEAR and CANCEL.SUCCESS actions the function clears events and sets empty array into store
 * @function documentForSigning
 *
 * @param {Object} state - Current value of the events field of the store (default value is an empty array)
 *
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of events
 *
 */

export const events = (state = [], action) => {
    switch (action.type) {
        case EVENTS.SET:
            return action.payload
        case EVENTS.CLEAR:
        case CANCEL.SUCCESS:
            return []
        default:
            return state
    }
}