import React, { Component } from 'react'
import { connect } from 'react-redux'
import logo from './logo.svg'
import { Image } from 'semantic-ui-react'

class Footer extends Component {
    isSuccess() {
        const { waiverList } = this.props
        let isSuccess = !!waiverList.length
        waiverList.forEach(waiver => {
            if (!waiver.isSigned) {
                isSuccess = false
            }
        })
        return isSuccess
    }

    render() {
        // const isKiosk = queryString.parse(window.location.search).kioskMode
        return (
            <div>
                {/* this.isSuccess() && <div className='ui secondary segment footer-success-message desktop'>
                    <h4 className="ui center aligned header">Do you have a business that uses waivers or other similar documents?</h4>
                    <a className={isKiosk ? 'disabled-link' : ''} href='https://www.waiversign.com/?utm_source=sample-website-flow&utm_medium=from-marketing-website&utm_campaign=sample-waiver-signup' target="_blank"><strong>Visit waiversign.com</strong></a>
                </div> */}
                <Image src={logo} className="logo" floated='right'/>
            </div>
        )
    }
}

export default connect(
    state => ({
        waiverList: state.waiverList
    })
)(Footer)
