import { LANGUAGE_SETTINGS } from '../constants'

const initialState = {
    default: 'en',
    current: 'en',
    availableLanguages: ['en', 'es']
}

const languageSettings = (state = {}, action) => {
    switch (action.type) {
        case LANGUAGE_SETTINGS.SET:
            return {
                ...action.payload
            }
        case LANGUAGE_SETTINGS.SET_CURRENT:
            return {
                ...state,
                current: action.payload
            }
        case LANGUAGE_SETTINGS.CLEAR:
            return initialState
        default:
            return state
    }
}

export default languageSettings