import { CANCEL, PERSONS } from '../constants'

/**
 * Set personsForSigning's data into store.
 *
 * @function personsForSigning
 *
 * @param {Object} state - Current value of the personsForSigning field of the store (default value is an empty object)
 *
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of personsForSigning
 *
 */


export const personsForSigning = (state = [], action) => {
    switch (action.type) {
        case PERSONS.SUCCESS:
            return action.payload
        case PERSONS.FAIL:
        case CANCEL.SUCCESS:
            return {}
        default:
            return state
    }
}
