import { PERSON_INFO, CANCEL } from '../constants'

/**
 * Set personInfo's data into store.
 *
 * This reducer processes one of defined actions' types:
 * [PERSON_INFO.SUCCESS, CANCEL.SUCCESS].
 * In any other case it returns the state which it got as a first param.
 *
 * On PERSON_INFO.SUCCESS action the function sets personInfo into store
 * On CANCEL.SUCCESS action the function clears personInfo and sets empty array into store
 * @function personInfo
 *
 * @param {Object} state - Current value of the personInfo field of the store (default value is an empty array)
 *
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of personInfo
 *
 */

export const personInfo = (state = [], action) => {
    switch (action.type) {
        case PERSON_INFO.SUCCESS:
            return action.payload
        case CANCEL.SUCCESS:
            return []
        default:
            return state
    }
}
