import React, { Component } from 'react'
import { connect } from 'react-redux'
import renderSelectField from '../Custom/selectField'
import renderInputField from '../Custom/inputField'
import { formValueSelector, Field } from 'redux-form'
import { Message } from 'semantic-ui-react'
import { createAction } from '../../sagas/createAction'
import { MINORS_DROPDOWN } from '../../constants'
import getTranslator from '../../multilingualism/getTranslator'
import fields from '../../multilingualism/fields'

const OPTIONS = [
    {key: '1', text: '1', value: 1},
    {key: '2', text: '2', value: 2},
    {key: '3', text: '3', value: 3},
    {key: '4', text: '4', value: 4},
    {key: '5', text: '5', value: 5},
    {key: '6', text: '6', value: 6},
    {key: '7', text: '7', value: 7},
    {key: '8', text: '8', value: 8},
    {key: '9', text: '9', value: 9},
    {key: '10', text: '10+', value: 10}
]

class MinorsCount extends Component {
    componentDidMount() {
        const { changeMinorsDropdown } = this.props
        changeMinorsDropdown(true)
    }

    handleMinorsSelectChange = (event, newValue) => {
        const { changeMinorsDropdown } = this.props
        if (newValue >= 10) {
            changeMinorsDropdown(false)
        }
    }

    handleMinorsInputChange = (event, newValue) => {
        if (!newValue.toString().match(/^[0-9]{0,9}$/gi) && newValue !== '') {
            event.preventDefault()
        }
    }

    convertToNumber = value => value === '' ? value : parseInt(value, 10)

    render() {
        const {
            participantOptions,
            minorsDropdown,
            formValues: { signing, minorsCount },
            languageSettings,
            waiverLanguage
        } = this.props
        const translate = getTranslator(languageSettings, waiverLanguage)
        const innerHtml = signing === 'both' ?
            translate(fields.YOU_MINORS_AGREEMENT_FOR, {age: participantOptions.adultAge}, parseInt(minorsCount, 10)) :
            translate(fields.MINORS_AGREEMENT_FOR, {age: participantOptions.adultAge}, parseInt(minorsCount, 10))
        return (
            <div className={signing === 'minors' || signing === 'both' ? 'ui segment margin-top' : ''}>
                {signing === 'minors' || signing === 'both' ?
                    <div>
                        {minorsDropdown ?
                            <Field
                                name="minorsCount"
                                component={renderSelectField}
                                label={translate(fields.MINORS_COUNT)}
                                onChange={this.handleMinorsSelectChange}
                                options={OPTIONS}
                                translate={translate}
                            />
                            : <Field
                                name="minorsCount"
                                component={renderInputField}
                                label={translate(fields.MINORS_COUNT)}
                                onChange={this.handleMinorsInputChange}
                                normalize={this.convertToNumber}
                                translate={translate}
                            />
                        }
                        {
                            !!minorsCount && minorsCount <= 15 && <Message positive>
                                <p dangerouslySetInnerHTML={{__html: innerHtml}}></p>
                            </Message>
                        }
                    </div>
                    : null
                }
            </div>
        )
    }
}

const selector = formValueSelector('SelectParticipantForm')
export default connect(
    state => ({
        participantOptions: state.participantOptions,
        minorsDropdown: state.selectParticipant.minorsDropdown,
        formValues: selector(state, 'minorsCount', 'signing'),
        languageSettings: state.languageSettings,
        waiverLanguage: state.waiverLanguage
    }), {
        changeMinorsDropdown: createAction(MINORS_DROPDOWN)
    }
)(MinorsCount)
