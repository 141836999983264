import moment from 'moment'
import fields from '../../multilingualism/fields'

export default (values, props) => {
    const { match: { params: { who, count } }, participantOptions, formFields } = props
    if (!participantOptions) return
    const counts = parseFloat(count, 10) || 1
    const nameOptions = []
    if (who !== 'minors') {
        nameOptions.push('adultOption')
    }
    if (who !== 'myself') {
        nameOptions.push('guardianOption')
        nameOptions.push('minorOption')
    }

    const errors = {}
    //source https://emailregex.com/
    // eslint-disable-next-line
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

    formFields.standard.forEach(field => {
        nameOptions.forEach(nameOption => {
            let valName
            if (nameOption === 'minorOption') {
                valName = 'minors'

                for (let idx = 0; idx < counts; idx++) {
                    if (!errors[valName]) {
                        errors[valName] = []
                    }
                    if (!errors[valName][idx]) {
                        errors[valName][idx] = {}
                    }
                    if (!values[valName]) {
                        values[valName] = []
                    }
                    if (!values[valName][idx]) {
                        values[valName][idx] = {}
                    }

                    if (field.name === 'Email' && values[valName][idx][field.name] && !emailRegex.test(values[valName][idx][field.name])) {
                        errors[valName][idx][field.name] = fields.INVALID_EMAIL
                    }
                    else if (field.name === 'Phone' && values[valName][idx][field.name] && values[valName][idx][field.name].phoneNumber
                        && values[valName][idx][field.name].phoneNumber.replace(/[^0-9.]/g, '').length < 10 && values[valName][idx][field.name].phoneNumber.replace(/\D+/ig, '').length) {
                        if (!errors[valName][idx][field.name]) {
                            errors[valName][idx][field.name] = {}
                        }
                        errors[valName][idx][field.name].phoneNumber = fields.INVALID_PHONE
                    }
                    else if (field.name === 'Phone' && field[nameOption] === 'required' && (!values[valName][idx][field.name] || !values[valName][idx][field.name].phoneNumber || !values[valName][idx][field.name].phoneNumber.replace(/[^0-9.]/g, '').length)) {
                        if (!errors[valName][idx][field.name]) {
                            errors[valName][idx][field.name] = {}
                        }
                        errors[valName][idx][field.name].phoneNumber = fields.REQUIRED
                    }
                    else if (field.name === 'Date of Birth' && field[nameOption] === 'required') {
                        if (!errors[valName][idx][field.name]) {
                            errors[valName][idx][field.name] = {}
                        }
                        if (!values[valName][idx][field.name] || values[valName][idx][field.name].month === undefined) {
                            errors[valName][idx][field.name].month = fields.MONTH_REQUIRE
                        }
                        if (!values[valName][idx][field.name] || !values[valName][idx][field.name].day) {
                            errors[valName][idx][field.name].day = fields.DAY_REQUIRE
                        }
                        if (!values[valName][idx][field.name] || !values[valName][idx][field.name].year) {
                            errors[valName][idx][field.name].year = fields.YEAR_REQUIRE
                        }
                        let birthDateString
                        if (values[valName][idx][field.name]) {
                            birthDateString = (values[valName][idx][field.name].month === undefined ? '' : (parseInt(values[valName][idx][field.name].month, 10) + 1)) + '/' + parseInt(values[valName][idx][field.name].day, 10) + '/' + parseInt(values[valName][idx][field.name].year, 10)
                        }

                        if (values[valName][idx][field.name] && moment(birthDateString, 'M/D/YYYY', true).isValid()) {
                            if (moment().diff(moment(birthDateString, 'M/D/YYYY'), 'years', true) >= participantOptions.adultAge) {
                                errors[valName][idx][field.name].birthDate = fields.INVALID_MINOR_BIRTH_DATE
                            }
                            if (moment().diff(moment(birthDateString, 'M/D/YYYY'), 'years', true) < 0) {
                                errors[valName][idx][field.name].birthDate = 'This individual has not yet been born. Either you\'re really good at predicting the future or this date of birth needs to be updated.'
                            }
                        }
                    } else if (field[nameOption] === 'required' && !values[valName][idx][field.name]) {
                        errors[valName][idx][field.name] = fields.REQUIRED
                    }
                }
            }
            else if (nameOption === 'guardianOption') {
                valName = 'guardian'
            }
            else if (nameOption === 'adultOption') {
                valName = 'adult'
            }

            if (nameOption !== 'minorOption') {
                if (!values[valName]) {
                    values[valName] = {}
                }
                if (!errors[valName]) {
                    errors[valName] = {}
                }

                if (field.name === 'Email' && field[nameOption] === 'required' && values[valName][field.name] && !emailRegex.test(values[valName][field.name])) {
                    errors[valName][field.name] = fields.INVALID_EMAIL
                }
                else if (field.name === 'Phone' && field[nameOption] === 'required' && (!values[valName][field.name] || !values[valName][field.name].phoneNumber || !values[valName][field.name].phoneNumber.replace(/[^0-9.]/g, '').length)) {
                    if (!errors[valName][field.name]) {
                        errors[valName][field.name] = {}
                    }
                    errors[valName][field.name].phoneNumber = fields.REQUIRED
                }
                else if (field.name === 'Phone' && values[valName][field.name] && values[valName][field.name].phoneNumber && values[valName][field.name].phoneNumber.replace(/\D+/ig, '').length < 8 && values[valName][field.name].phoneNumber.replace(/\D+/ig, '').length) {
                    if (!errors[valName][field.name]) {
                        errors[valName][field.name] = {}
                    }
                    errors[valName][field.name].phoneNumber = fields.INVALID_PHONE
                }
                else if (field.name === 'Date of Birth' && field[nameOption] === 'required') {
                    if (!errors[valName][field.name]) {
                        errors[valName][field.name] = {}
                    }
                    if (!values[valName][field.name] || values[valName][field.name].month === undefined) {
                        errors[valName][field.name].month = fields.MONTH_REQUIRE
                    }
                    if (!values[valName][field.name] || !values[valName][field.name].day) {
                        errors[valName][field.name].day = fields.DAY_REQUIRE
                    }
                    if (!values[valName][field.name] || !values[valName][field.name].year) {
                        errors[valName][field.name].year = fields.YEAR_REQUIRE
                    }

                    let birthDateAdultString
                    if (values[valName][field.name]) {
                        birthDateAdultString = (values[valName][field.name].month === undefined ? '' : (parseInt(values[valName][field.name].month, 10) + 1)) + '/' + parseInt(values[valName][field.name].day, 10) + '/' + parseInt(values[valName][field.name].year, 10) || ''
                    }

                    if (values[valName][field.name] && moment(birthDateAdultString, 'M/D/YYYY', true).isValid()) {
                        if (moment().diff(moment(birthDateAdultString, 'M/D/YYYY'), 'years', true) < participantOptions.adultAge) {
                            errors[valName][field.name].birthDate = fields.TOO_YOUNG
                        }
                        if (moment().diff(moment(birthDateAdultString, 'M/D/YYYY'), 'years', true) < 0) {
                            errors[valName][field.name].birthDate = 'This individual has not yet been born. Either you\'re really good at predicting the future or this date of birth needs to be updated.'
                        }
                    }
                }
                else if (field[nameOption] === 'required' && field.name === 'Identification' && !values[valName][field.name]) {
                    if (!errors[valName][field.name]) {
                        errors[valName][field.name] = {}
                    }
                    errors[valName][field.name].type = fields.REQUIRED
                    errors[valName][field.name].number = fields.REQUIRED
                }
                else if (field[nameOption] === 'required' && field.name === 'Identification' && !values[valName][field.name].number) {
                    if (!errors[valName][field.name]) {
                        errors[valName][field.name] = {}
                    }
                    errors[valName][field.name].number = fields.REQUIRED
                }
                else if (field.name === 'Identification' && values[valName][field.name] && values[valName][field.name].number && values[valName][field.name].number.length < 4) {
                    if (!errors[valName][field.name]) {
                        errors[valName][field.name] = {}
                    }
                    errors[valName][field.name].number = fields.INVALID_IDENTIFICATION
                }
                else if (field[nameOption] === 'required' && field.name === 'Identification' && !values[valName][field.name].type) {
                    if (!errors[valName][field.name]) {
                        errors[valName][field.name] = {}
                    }
                    errors[valName][field.name].type = fields.REQUIRED
                }
                else if (field[nameOption] === 'required' && !values[valName][field.name]) {
                    errors[valName][field.name] = fields.REQUIRED
                }
            }
        })

    })

    formFields.custom.forEach(customField => {
        nameOptions.forEach(nameOption => {
            let valName

            if (nameOption === 'minorOption') {
                valName = 'minors'

                for (let idx = 0; idx < counts; idx++) {
                    if (!errors[valName]) {
                        errors[valName] = []
                    }
                    if (!errors[valName][idx]) {
                        errors[valName][idx] = {}
                    }
                    if (!values[valName]) {
                        values[valName] = []
                    }
                    if (!values[valName][idx]) {
                        values[valName][idx] = {}
                    }
                    if (!errors[valName][idx].customFieldList) {
                        errors[valName][idx].customFieldList = {}
                    }

                    if (customField[nameOption] === 'required' && customField.fieldType === 'checkbox' && values[valName][idx].customFieldList && (!values[valName][idx].customFieldList[customField.name] || !values[valName][idx].customFieldList[customField.name].length)) {
                        errors[valName][idx].customFieldList[customField.name] = fields.REQUIRED
                    }
                    else if (customField[nameOption] === 'required' && (!values[valName][idx].customFieldList || !values[valName][idx].customFieldList[customField.name])) {
                        errors[valName][idx].customFieldList[customField.name] = fields.REQUIRED
                    }
                }
            }
            else if (nameOption === 'guardianOption') {
                valName = 'guardian'
            }
            else if (nameOption === 'adultOption') {
                valName = 'adult'
            }

            if (nameOption !== 'minorOption') {
                if (!values[valName]) {
                    values[valName] = {}
                }
                if (!errors[valName]) {
                    errors[valName] = {}
                }
                if (!errors[valName].customFieldList) {
                    errors[valName].customFieldList = {}
                }

                if (customField[nameOption] === 'required' && customField.fieldType === 'checkbox' && (!values[valName].customFieldList || !values[valName].customFieldList[customField.name] || !values[valName].customFieldList[customField.name].length)) {
                    errors[valName].customFieldList[customField.name] = fields.REQUIRED
                }
                else if (customField[nameOption] === 'required' && (!values[valName].customFieldList || !values[valName].customFieldList[customField.name])) {
                    errors[valName].customFieldList[customField.name] = fields.REQUIRED
                }
            }
        })
    })

    formFields.event.forEach(eventField => {
        if (!values.event) {
            values.event = {}
        }
        if (!errors.event) {
            errors.event = {}
        }

        if (eventField.required && !values.event[eventField.name]) {
            errors.event[eventField.name] = fields.REQUIRED
        }
    })

    return errors
}
