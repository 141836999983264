import React from 'react'
import { Form, Label, Message, Header, Checkbox } from 'semantic-ui-react'

export default ({
        input,
        apply,
        title,
        body,
        signature,
        nameBtn,
        meta: { touched, error },
        id
    }) =>
    <Form.Field>
        <Message info={!apply} positive={apply} className="margin-top">
            <Message.Header>{title}</Message.Header>
            <p>{body}</p>
            {!apply && <Header as='h2' className="signature margin-top">{signature}</Header>}
            <Checkbox
                id={id}
                type="checkbox"
                onChange={(e, data) => {
                    input.onChange(data.checked)
                    input.onBlur(undefined, true)
                }}
                label={
                    apply ?
                        <Header as='p' className="signature link">{signature}</Header>
                        :
                        <label className="ui button primary margin-top not-display-checkbox" id={id + '-label'}>{nameBtn}</label>
                }/>
        </Message>
        {touched && (error && <Label pointing basic color='red'> {error} </Label>)}
    </Form.Field>
