import React, { Component } from 'react'
import { Modal } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

class KioskMode extends Component {
    constructor() {
        super()
        this.state = {
            showModal: false
        }
        this.handleUserActivity = this.handleUserActivity.bind(this)
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.updateModalCountdown = this.updateModalCountdown.bind(this)
    }
    componentDidMount() {
        window.onkeypress = this.handleUserActivity
        window.onscroll = this.handleUserActivity
        this.kioskCountdownTimeout = setTimeout(this.openModal, 60000)
    }
    componentWillUnmount() {
        window.onkeypress = null
        window.onscroll = null
        clearInterval(this.modalCountdownInterval)
        clearTimeout(this.kioskCountdownTimeout)
    }
    handleUserActivity() {
        clearTimeout(this.kioskCountdownTimeout)
        this.kioskCountdownTimeout = setTimeout(this.openModal, 60000)
    }
    updateModalCountdown() {
        const { modalCountdown } = this.state
        if (modalCountdown === 1) {
            this.resetTimer(true)
            return false
        }
        this.setState({
            modalCountdown: modalCountdown - 1
        })
    }
    openModal() {
        this.setState({
            showModal: true,
            modalCountdown: 10
        })
        this.modalCountdownInterval = setInterval(this.updateModalCountdown, 1000)
    }
    closeModal() {
        this.setState({
            showModal: false
        })
    }
    resetTimer(goBack) {
        const { push, signingProcess: { returnUrl, beginSigningUrl } } = this.props
        this.closeModal()
        clearInterval(this.modalCountdownInterval)
        this.kioskCountdownTimeout = setTimeout(this.openModal, 60000)
        if (!goBack) {
            return false
        }
        returnUrl ? window.open(returnUrl, '_self') : push(beginSigningUrl)
    }
    render() {
        const { showModal, modalCountdown } = this.state
        return (
            <Modal size="small" open={showModal}>
                <Modal.Header>Do you need more time?</Modal.Header>
                <Modal.Content>
                    <p id="alertSentence">This process will go back to the starting point in...</p>
                    <p><strong>{modalCountdown}</strong></p>
                </Modal.Content>
                <Modal.Actions className="txt-left">
                    <button className="ui button primary" id="moreTimeButton" onClick={() => this.resetTimer(false)}>I need more time</button>
                    <button className="ui button secondary" id="goBackButton" onClick={() => this.resetTimer(true)}>Go back</button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default connect(
    state => ({
        signingProcess: state.signingProcess || {}
    }),
    { 
        push
    }
)(KioskMode)