import React from 'react'
import { Form, Label } from 'semantic-ui-react'

export default ({
    required,
    placeholder,
    input,
    label,
    type,
    description,
    meta: { touched, error },
    translate,
    id
}) =>
    <Form.Field required={required}>
        <label htmlFor={id}>{label}</label>
        {
            description && <div className='description'>{description}</div>
        }
        <Form.TextArea {...input} id={id} placeholder={placeholder || label} onChange={(param,data) => input.onChange(data.value)} required={required} error={!!required && !!touched && !!error} />
        {touched && (error && <Label pointing basic color='red' id={`error_${label.replace(/ /g, '')}`}> {translate(error)} </Label>)}
    </Form.Field>
