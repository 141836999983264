import React from 'react'
import { Form, Label } from 'semantic-ui-react'

export default ({
    input,
    options,
    required,
    label,
    description,
    meta: { touched, error },
    translate,
    id
}) => {
    const {onChange, onBlur} = input
    const inputValue = input.value

    const checkboxes = options.map((value, idx) => {
        const handleChange = ({target}) => {
            const arr = [...inputValue]
            if (target.checked) {
                arr.push(value)
            }
            else {
                arr.splice(arr.indexOf(value), 1)
            }
            return onChange(arr)
        }

        const handleBlur = e => {
            onBlur(undefined, true)
        }

        const checked = inputValue.includes(value)
        return (
            <Form.Group key={idx}>
                <div className="field ui checkbox">
                    <input type='checkbox' id={`${id}-${idx}`} checked={checked} onBlur={handleBlur} onChange={handleChange} data-checked={!!input.value}/>
                    <label htmlFor={`${id}-${idx}`} style={{cursor: 'pointer'}}>{value}</label>
                </div>
            </Form.Group>
        )
    })

    return (
        <Form.Field required={required}>
            <label>{label}</label>
            {
                description && <div className='description'>{description}</div>
            }
            {checkboxes}
            {touched && (error && <Label pointing basic color='red' id={`error_${label.replace(/ /g, '')}`}> {translate(error)} </Label>)}
        </Form.Field>
    )
}
