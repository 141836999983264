import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {Dropdown} from 'semantic-ui-react'
import { LANGUAGE_SETTINGS } from '../../constants'

class LanguageDropdown extends PureComponent {
    constructor(props) {
        super(props);
        this.changeLanguage = this.changeLanguage.bind(this);
        this.getAvailableLanguages = this.getAvailableLanguages.bind(this)
    }

    getAvailableLanguages(availableLanguagesList, waiverLanguage) {

        const languagesAvailabelToShow = [];
        availableLanguagesList.forEach(lang => {
            if(waiverLanguage[lang]) {
                const language = {
                    key: lang,
                    value: lang,
                    id: lang,
                    text: waiverLanguage[lang].languageLabel
                };
                languagesAvailabelToShow.push(language)
            }
            else{
                console.log('language', lang, 'not found!');
                console.log('waiverLanguage', waiverLanguage)
            }
        });

        languagesAvailabelToShow.sort((a, b) => {
            if (a.text > b.text) {
                return 1;
            }
            if (b.text > a.text) {
                return -1;
            }
            return 0;
        });

        return languagesAvailabelToShow
    }

    changeLanguage(event, data) {
        data.options.forEach(option => {
            if(option.key === data.value) {
                this.props.dispatch({
                    type: LANGUAGE_SETTINGS.SET_CURRENT,
                    payload: option.key
                })
            }
        })
    }

    render() {
        const {languageSettings, waiverLanguage} = this.props;
        const languages = this.getAvailableLanguages(languageSettings.availableLanguages, waiverLanguage);

        return languageSettings.availableLanguages.length > 1 ? (
            <Dropdown
                button
                className='tiny icon'
                floating
                labeled
                icon='world'
                onChange={this.changeLanguage}
                options={languages}
                defaultValue={languageSettings.current}
                id={'languageSelect'}
            />
        ) : (<div/>)
    }
}

export default connect(
    state => ({
        languageSettings: state.languageSettings,
        waiverLanguage: state.waiverLanguage
    })
)(LanguageDropdown)
