import React, { Component } from 'react'
import { Form, Label } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import getTranslator from '../../multilingualism/getTranslator'
import fields from '../../multilingualism/fields'

class StateField extends Component {
    validCountry = country => {
        if (typeof country === 'string') {
            if (JSON.parse(country).name === 'United States' || JSON.parse(country).name === 'Canada') {
                return true
            } else {
                return false
            }
        } else {
            if (country.name === 'United States' || country.name === 'Canada') {
                return true
            } else {
                return false
            }
        }
    }
    render() {
        const {
            required,
            input,
            placeholder,
            label,
            meta: { touched, error },
            country,
            states,
            languageSettings,
            id,
            waiverLanguage
        } = this.props
        let options
        if (states.states && typeof country === 'string') {
            options = (country && JSON.parse(country).name === 'Canada') ? states.Canada : states.states
        }
        else if (states.states && typeof country === 'object') {
            options = (country && country.name === 'Canada') ? states.Canada : states.states
        }
        else {
            options = []
        }

        if (typeof input.value === 'object') {
            input.value = JSON.stringify(input.value)
        }
        const translate = getTranslator(languageSettings, waiverLanguage)

        return (
            <Form.Field required={required}>
                <label htmlFor={id}>{label}</label>
                {country && this.validCountry(country) ?
                    <div className={`field ${!!touched && !!error ? 'error' : ''}`}>
                        <select
                            {...input}
                            id={id}
                            label={label}
                            placeholder={placeholder || label}
                            onChange={({target}) => input.onChange(target.value)}
                            required={required}
                            className='ui dropdown'
                        >
                            {
                                [
                                    {key: '', text: translate(fields.SELECT_STATE), value: ''},
                                    ...options.map(option => ({key: option.name, text: option.name, value: JSON.stringify(option)}))
                                ].map(option => (<option key={option.key} value={option.value}>{option.text}</option>))
                            }
                        </select>
                    </div>
                        :
                    <Form.Input
                        {...input}
                        id={id}
                        placeholder={placeholder || label}
                        onChange={(param, data) => input.onChange(data.value)}
                        required={required}
                        error={!!touched && !!error}
                    />
                }
                {touched && (error && <Label pointing basic color='red' id={`error_${id}`}> {translate(error)} </Label>)}
            </Form.Field>
        )
    }
}

const selector = formValueSelector('CollectParticipantInfoForm')
export default connect(
    (state, ownProps) => {
        const formName = ownProps.input.name.split('.')[0]
        const countryFieldPath = `${formName}.Country`
        return {
            country: selector(state, countryFieldPath),
            states: state.additionalInformation.states || {},
            languageSettings: state.languageSettings,
            waiverLanguage: state.waiverLanguage
        }
    }
)(StateField)
