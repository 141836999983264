import {LANGUAGE} from '../constants'

/**
 * Set signedWaiver' data into store.
 *
 * @function signedWaiver
 *
 * @param {Object} state - Current value of the participantOptions field of the store (default value is an empty object)
 *
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of signedWaiver
 *
 */

export const waiverLanguage = (state = {}, action) => {
    switch (action.type) {
        case LANGUAGE.SUCCESS:
            return action.payload
        default:
            return state
    }
}

export default waiverLanguage
