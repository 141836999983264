import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import validate from './validate'
import { Form, Header, Button, Grid, Dimmer, Loader, Segment, Message, Responsive } from 'semantic-ui-react'
import ParticipantForm from './participantForm'
import EventForm from './eventForm'
import { createAction } from '../../sagas/createAction'
import {ADDITIONAL_INFORMATION, PERSON_INFO, CANCEL, LANGUAGE} from '../../constants'
import LanguageDropdown from '../Ui/LanguageDropdown'
import getTranslator from '../../multilingualism/getTranslator'
import fields from '../../multilingualism/fields'
import { EmailOptInSettings, SmsOptInSettings } from '../../constants'

const stringifyCountryState = person => {
    if (person && person.Country && typeof person.Country === 'object') {
        person.Country = JSON.stringify(person.Country)
    }
    if (person && person.State && typeof person.State === 'object') {
        person.State = JSON.stringify(person.State)
    }
    return person
}

const identifySigning = ({ match, signingSession }) => {
    const { personInfoList } = signingSession

    let signing
    if (!Object.keys(signingSession).length) {
        signing = match.params.who
    } else {
        const minorCount = personInfoList.filter(p => !p.isAdult).length
        if (personInfoList.length === 1 && !minorCount) {
            signing = 'myself'
        }
        else if (personInfoList.length === minorCount) {
            signing = 'minors'
        }
        else if (personInfoList.length !== minorCount) {
            signing = 'both'
        }
    }
    return signing
}

const shouldSendEmail = optInSetting => {
    const {
        OPTED_IN_BY_DEFAULT: { key : optedInByDefault },
        OPTED_IN_AUTO_AND_HIDDEN: { key : optedInAutoAndHidden}
    } = EmailOptInSettings
    return  optInSetting === optedInByDefault || optInSetting === optedInAutoAndHidden
}


const shouldSendSms = optInSetting => {
    const {
        OPTED_IN_BY_DEFAULT: { key : optedInByDefault },
        OPTED_IN_AUTO_AND_HIDDEN: { key : optedInAutoAndHidden}
    } = SmsOptInSettings
    return optInSetting === optedInByDefault || optInSetting === optedInAutoAndHidden
}

const getPrefilledState = (country, states, prefilledState) => {
    let options

    if (states.states && typeof country === 'string') {
        const countryName = (country && country.name) ? country.name : '';
        if(!countryName || (countryName !== 'Canada' && countryName !== 'United States')) return '';

        options = (countryName === 'Canada') ? states.Canada : states.states
    }
    else if (states.states && !!country && typeof country === 'object') {
        const countryName = (country && country.name) ? country.name : '';
        if(!countryName || (countryName !== 'Canada' && countryName !== 'United States')) return '';

        options = (country && country.name === 'Canada') ? states.Canada : states.states
    }
    else {
        options = []
        return '';
    }

    const stateObj = options.find(item => item.name === JSON.parse(prefilledState).name);

    return stateObj ? JSON.stringify(stateObj) : stateObj;
}
class CollectParticipantInfo extends PureComponent {
    componentDidMount() {
        const { loadAdditionalInformation, location, match } = this.props
        loadAdditionalInformation({ location, match })
    }

    componentWillReceiveProps(nextProps) {
        const {
            match,
            personInfo,
            formFields,
            initialized,
            countries,
            states,
            languageSettings,
            waiverLanguage,
			businessEntity
        } = this.props

        if (formFields.standard.length && !initialized) {
            const translate = getTranslator(languageSettings, waiverLanguage)

            const defaultValues = { Phone: { phoneType: 'Mobile' } }
            const [ field ] = formFields.standard.filter(field => field.name === 'Phone')
            const initMinors = []

            for (let i = 0; i < match.params.count; i++) {
                if (field.minorOption !== 'hidden') {
                    initMinors.push({ ...defaultValues })
                } else {
                    initMinors.push({})
                }
            }

            const initValues = {
                adult: field.adultOption !== 'hidden' ? { ...defaultValues } : {},
                guardian: field.guardianOption !== 'hidden' ? { ...defaultValues } : {},
                minors: initMinors
            }

            Object.keys(personInfo).forEach(person => {
                if (!personInfo[person].Phone) {
                    personInfo[person].Phone = defaultValues.Phone
                }
            })

            const searchParams = new URLSearchParams(document.location.search);
            Object.keys(initValues).forEach(key => {
                const optInSetting = businessEntity?.emailOptInSettings?.waiverSignForm?.setting
                const optInSmsSetting = businessEntity?.smsOptInSettings?.waiverSignForm?.setting
                const countryPrefillData = searchParams.get('country');
                const statePrefillData = searchParams.get('state');
                const prefilledCountryObj = countryPrefillData ? JSON.parse(countryPrefillData) : countryPrefillData;
                const prefilledStateObj = getPrefilledState(prefilledCountryObj, states, statePrefillData)

                // name = 'United States'
                let countryObj;
                if(prefilledCountryObj && prefilledCountryObj.name) {
                    countryObj = [
                        {key: '', text: translate(fields.SELECT_COUNTRY), value: ''},
                        ...countries.map(country => ({key: country.name, text: country.name, value: JSON.stringify(country)}))
                    ].find((item) => item.key === prefilledCountryObj.name);
                }

                if (key !== 'minors' && initValues[key] && initValues[key].Phone && initValues[key].Phone.phoneNumber && !initValues[key].Phone.phoneType) {
                    initValues[key].Phone.phoneType = 'Mobile'
                }
                if (key === 'minors') {
                    initValues[key] = initValues[key].map(minor => {
                        if (minor.Phone && minor.Phone.phoneNumber && !minor.Phone.phoneType) {
                            minor.Phone.phoneType = 'Mobile'
                        }
                        minor.sendEmail = shouldSendEmail(optInSetting);
                        minor.sendSms = shouldSendSms(optInSmsSetting);
                        minor.Country = countryObj ? countryObj.value: null;
                        minor.State = prefilledStateObj;
                        return minor
                    })
                }
                if (key === 'adult' || key === 'guardian') {
                    initValues[key].sendEmail = shouldSendEmail(optInSetting)
                    initValues[key].sendSms = shouldSendSms(optInSmsSetting)
                    initValues[key].Country = countryObj ? countryObj.value: null;
                    initValues[key].State = prefilledStateObj;
                }
            }, this);

            const minorsInfo = personInfo.filter(person => !person.isAdult && !person.isGuardian)
            const adultInfo = personInfo.filter(person => person.isAdult)[0]
            const guardianInfo = personInfo.filter(person => person.isGuardian)[0]

            const infoMap = {
                minors: [],
                adult: {},
                guardian: {}
            }
            personInfo.forEach(p => {
                if(p['Date of Birth']) {
                    const date = new Date(p['Date of Birth'])
                    const month = date.getMonth()
                    const year = date.getUTCFullYear()
                    const day = date.getDate()
                    if (!p.isAdult && !p.isGuardian) {
                        infoMap.minors.push({
                            ...p,
                            'Date of Birth': {month, day, year}
                        })
                    }
                    else if (p.isAdult) {
                        infoMap.adult = {'Date of Birth': {month, day, year}}
                    }
                    else if (p.isGuardian) {
                        infoMap.guardian = {'Date of Birth': {month, day, year}}
                    }
                }
            })

            const minors = initValues.minors.length ? initValues.minors.map((initial, idx) => {
                console.log()
                return {...initial, ...minorsInfo}
            }) : minorsInfo.length > 0 ? minorsInfo : []

            nextProps.initialize({
                adult: {
                    ...initValues.adult,
                    ...stringifyCountryState(adultInfo),
                    ...infoMap.adult
                },
                guardian: {
                    ...initValues.guardian,
                    ...stringifyCountryState(guardianInfo),
                    ...infoMap.guardian
                },
                minors: infoMap.minors.length ? infoMap.minors : [...minors]
            })
        }
    }

    handleCancel = () => {
        const { signingProcess: { returnUrl }, cancel, match, location } = this.props
        returnUrl ? window.open(returnUrl, '_self') : cancel({match, location})
    }

    render() {
        const {
            handleSubmit,
            match,
            loading,
            signingSession,
            noEvent,
            events,
            submitFailed,
            businessEntity,
            languageSettings,
            waiverLanguage
        } = this.props
        const translate = getTranslator(languageSettings, waiverLanguage)
        const showLanguageDropdown = languageSettings.availableLanguages.length > 1
        const { personInfoList } = signingSession
        const signing = identifySigning(this.props)
        const Minors = []
        let count = 1

        if (!Object.keys(signingSession).length) {
            count = parseFloat(match.params.count, 10) || 1
        } else {
            count = personInfoList.filter(p => !p.isAdult).length || 1
        }

        if (signing !== 'myself' ) {
            for (let idx = 0; idx < count; idx++) {
                Minors.push(
                    <ParticipantForm
                        name={`minors[${idx}]`}
                        key={idx}
                        idx={idx}
                        formName={`minors[${idx}]`}
                        signing="minors"
                    />
                )
            }
        }

        if (loading) {
            return <Segment padded='very'>
            <Dimmer active inverted>
              <Loader content='Loading' />
            </Dimmer>
          </Segment>
        }


        return (
                <Form onSubmit={handleSubmit} noValidate>
                    {showLanguageDropdown ? <Responsive as={'div'} className="ui grid" minWidth={768}>
                        <div className="three wide centered column margin-bottom">
                            <LanguageDropdown/>
                        </div>
                    </Responsive> : null}
                    {showLanguageDropdown ? <Responsive as={'div'} className="ui grid" maxWidth={767}>
                        <div className="seven wide centered column">
                            <LanguageDropdown/>
                        </div>
                    </Responsive> : null}
                    <Header as='h2' id='documentHeader'>{translate(fields.DOCUMENT_HEADER)}</Header>
                    <p id="headerContent">{translate(fields.DOCUMENT_HEADER_CONTENT, {businessName: businessEntity.name})}</p>
                    <Grid columns={1}>
                        {(!noEvent && events.length !== 0) ?
                            <Grid.Row>
                                <Grid.Column>
                                    <EventForm/>
                                </Grid.Column>
                            </Grid.Row>
                            : null
                        }
                        {signing === 'myself' ?
                            <Grid.Row>
                                <Grid.Column>
                                    {/*<Header as='h5' className="padding-top" id={`participantFormHeader`}>{translate(fields.PARTICIPANT_HEADER)}</Header>*/}
                                <ParticipantForm
                                    name="adult"
                                    formName="adult"
                                    signing="myself"
                                    headerName={fields.PARTICIPANT_HEADER}
                                 />
                                </Grid.Column>
                            </Grid.Row>
                            : null}
                        {signing === 'minors' ?
                            <Grid.Row>
                                <Grid.Column>
                                    {/*<Header as='h5' className="padding-top" id={`participantFormHeader`}>{translate(fields.PARENT_HEADER)}</Header>*/}
                                    <ParticipantForm
                                        name="guardian"
                                        formName="guardian"
                                        signing="guardian"
                                        headerName={fields.PARENT_HEADER}
                                    />
                                    {Minors.map(minor => minor)}
                                </Grid.Column>
                            </Grid.Row>
                            : null}
                        {signing === 'both' ?
                            <Grid.Row>
                                <Grid.Column>
                                    {/*<Header as='h5' className="padding-top" id={`participantFormHeader`}>{translate(fields.ADULT_HEADER)}</Header>*/}
                                    <ParticipantForm
                                        name="adult"
                                        formName="adult"
                                        signing="myself"
                                        headerName={fields.ADULT_HEADER}
                                    />
                                    {Minors.map(minor => minor)}
                                </Grid.Column>
                            </Grid.Row>
                            : null}
                            <Grid.Column>
                                {translate(fields.PRIVACY_WAIVERSIGN, {businessName: businessEntity.name, businessName2: businessEntity.name, businessEmail: businessEntity.businessEmail})}
                            </Grid.Column>
                    </Grid>
                    {submitFailed && <Message
                        negative
                        content={translate(fields.SCROLL_UP)}
                    />}
                    <Responsive as={'div'} className="ui basic segment padding-none" minWidth={768}>
                        <Button type="submit" id="nextButton" size="big" className="margin-top" primary>{translate(fields.NEXT)}</Button>
                        {signingSession.personInfoList && !(signingSession.returnUrl && signingSession.returnUrl.includes('resmarksystems.com')) ? null :
                            <Button type="button" id="backButton" size="big" className="margin-top margin-left" onClick={this.handleCancel} secondary>{translate(fields.BACK)}</Button>
                        }
                    </Responsive>
                    <Responsive as={'div'} className="ui basic segment padding-none"maxWidth={767}>
                        <Button type="submit" id="nextButton" size="big" className="margin-top" primary>{translate(fields.NEXT)}</Button>
                        {signingSession.personInfoList && !(signingSession.returnUrl && signingSession.returnUrl.includes('resmarksystems.com')) ? null :
                        <Button type="button" id="backButton" size="big" onClick={this.handleCancel}
                                secondary>{translate(fields.BACK)}</Button>
                        }
                    </Responsive>

                </Form>
        )
    }
}

const handleSubmitForm = (data, dispatch, { informationComplete, match, location, signingSession }) => {
    const signing = match.params.who || identifySigning({match, signingSession})
    informationComplete({ url: match.url, signing, data, location })
}

const CollectParticipantInfoForm = reduxForm({
    form: 'CollectParticipantInfoForm',
    onSubmit: handleSubmitForm,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnBlur: true,
    validate,
})(CollectParticipantInfo)

export default connect(
    state => ({
        personInfo: state.personInfo,
        countries: state.additionalInformation.countries || [],
        states: state.additionalInformation.states || {},
        person: state.person,
        formFields: state.formFields,
        participantOptions: state.participantOptions,
        loading: state.loading,
        signingSession: state.signingSession,
        events: state.events,
        noEvent: state.signingProcess.noEvent,
        businessEntity: state.additionalInformation.businessEntity || { name: '' },
        waivers: state.waiverList,
        languageSettings: state.languageSettings,
        waiverLanguage: state.waiverLanguage,
        signingProcess: state.signingProcess
    }),
    {
        informationComplete: createAction(PERSON_INFO),
        loadAdditionalInformation: createAction(ADDITIONAL_INFORMATION),
        cancel: createAction(CANCEL)
    }
)(CollectParticipantInfoForm)
