// import en from './translations/en'
// import es from './translations/es'

let currentLanguage = 'en'
let languageMap;

function translate (field, params, smartCount) {
    const translations = languageMap[currentLanguage] || {}
    let translation = translations[field]
    if (!translation) {
        return field
    }

    if(params) {
        Object.keys(params).forEach(key => {
            translation = translation.replace(`@{${key}}`, params[key])
        })
    }

    if (smartCount && typeof smartCount === 'number') {
        if(translation.indexOf('@{SMART_NUMBER}') !== -1) {
            // eslint-disable-next-line
            if (translation.indexOf('@{SMART_NUMBER}') < translation.indexOf(translation.match(/\{[A-Za-z \/(\/)]+[ ]?[|]{4}[ ]?[A-Za-z \/(\/)]+\}/)[0])) { //  /\{[A-Z]+[ ]?[|]{4}[ ]?[A-Z]+\}/  == for example {MINOR |||| MINORS}
                translation = translation.replace('@{SMART_NUMBER}', smartCount)
                // eslint-disable-next-line
                const possibleValuesString = translation.match(/\{[A-Za-z \/(\/)]+[ ]?[|]{4}[ ]?[A-Za-z \/(\/)]+\}/)[0].replace('{', '').replace('}', '')
                const possibleValues = {
                    single: possibleValuesString.split('||||')[0].trim(),
                    plural: possibleValuesString.split('||||')[1].trim()
                }
                // eslint-disable-next-line
                translation = smartCount > 1 ? translation.replace(/\{[A-Za-z \/(\/)]+[ ]?[|]{4}[ ]?[A-Za-z \/(\/)]+\}/, possibleValues.plural) : translation.replace(/\{[A-Za-z \/(\/)]+[ ]?[|]{4}[ ]?[A-Za-z \/(\/)]+\}/, possibleValues.single)
            }
        }
    }
    return translation
}

const getTranslator = (languageSettings = {current: currentLanguage}, languages) => {
    currentLanguage = languageSettings.current

    languageMap = languages || {}

    return translate
}


export default getTranslator
