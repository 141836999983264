import { LOADING, LOADED, CANCEL } from '../constants'

/**
 * Set loading's data into store.
 *
 * @function documentForSigning
 *
 * @param {Object} state - Current value of the loading field of the store (default value is false)
 *
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of loading
 *
 */

export const loading = (state = false, action) => {
    switch (action.type) {
        case LOADING.SUCCESS:
            return true
        case LOADED.SUCCESS:
        case CANCEL.SUCCESS:
            return false
        default:
            return state
    }
}
