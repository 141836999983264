import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import { UI } from '../../constants'

class ErrorPopup extends Component {
    handleClose = () => {
        this.props.dispatch({
            type: UI.ERROR.FAIL
        })
    }
    render() {
        const { error } = this.props
        return (
            <Modal size="mini" open={!!error} onClose={this.handleClose}>
                <Modal.Content>
                    <p className="txt-center">{error}</p>
                </Modal.Content>
                <Modal.Actions className="txt-center">
                    <button id="errorPopupButton" className="ui button primary" onClick={this.handleClose}>Ok</button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default connect(
    state => ({
        error: state.ui.errorPopup
    })
)(ErrorPopup)