import { call, put, takeLatest, select } from 'redux-saga/effects'
import { ADDITIONAL_INFORMATION, SIGNING, LOADING, LOADED, UI, WAIVER_LIST, FORM_FIELDS, PERSON_INFO, EVENTS, LANGUAGE_SETTINGS } from '../constants'
import { fetchGet, getPostalCode, fetchPost } from '../apiCalls/index'
import { change, startAsyncValidation, stopAsyncValidation } from 'redux-form'
import queryString from 'query-string'
import moment from 'moment'
import fields from '../multilingualism/fields'


/**
 * Load documents by Ids
 * Saga will be launched on actions like: LOADING,
 *
 * @generator
 * @function loadBydocumentIds
 * @param {Object} location @see {@link https://reacttraining.com/react-router/core/api/Route/location-object}
 * @param {Object} match @see {@link https://reacttraining.com/react-router/core/api/match}
 * @param {string} match.params.type - endpoint type
 * @param {string} match.params.ids - document Ids
 * @param {string} match.params.count - person count
 * @param {string} match.params.who - person type
 * saga
 */

function* loadBydocumentIds(location, match) {
    const state = yield select()
    const { type, ids, count, who, entityId } = match.params

    yield put({
        type: LOADING.SUCCESS
    })

    const response = yield call(fetchGet, `additional/information`)
    
    yield put({
        type: ADDITIONAL_INFORMATION.COUNTRIES.SUCCESS,
        payload: response.payload
    })

    let returnUrl = `${window.location.origin}/${type}/${ids}${location.search}`
    if(entityId){
        returnUrl = `${window.location.origin}/e/${entityId}/${type}/${ids}${location.search}`
    }

    let beginSigningUrl = `/${type}/${ids}${location.search}`
    if(entityId){
        beginSigningUrl = `/e/${entityId}/${type}/${ids}${location.search}`
    }

    const signingProcessInformation = {
        partialNotification: true,
        returnUrl: returnUrl,
        beginSigningUrl: beginSigningUrl
    }

    const query = queryString.parse(location.search)
    if (query.partialNotification === 'false') {
        signingProcessInformation.partialNotification = false
    }
    if (query.kioskMode) {
        signingProcessInformation.kioskMode = query.kioskMode
    }
    if (query.referenceId) {
        signingProcessInformation.referenceId = query.referenceId
    }
    if (query.callbackUrl) {
        signingProcessInformation.callbackUrl = query.callbackUrl
    }
    if (query.returnUrl) {
        signingProcessInformation.returnUrl = query.returnUrl
    }
    if (query.eventName) {
        signingProcessInformation.eventName = query.eventName
    }
    if (query.eventDate) {
        signingProcessInformation.eventDate = query.eventDate
    }
    if (query.event && query.event === 'none') {
        signingProcessInformation.noEvent = true
    }
   
    yield put({
        type: SIGNING.PROCESS.SUCCESS,
        payload: signingProcessInformation
    })

    const documentIdList = ids.split(',')
    const personInfoList = []

    if (who !== 'minors') {
        personInfoList.push({
            isAdult: true
        })
    }
    if (who !== 'myself') {
        for (let index = 0; index < count; index += 1) {
            personInfoList.push({
                isAdult: false
            })
        }
    }

    try {
        const response = yield call(fetchPost, `${type}/form`, {
            personInfoList,
            documentIdList,
            signing: who
        })
        if (response.success) {
            const languageSettings = state.languageSettings.current ? state.languageSettings : getDefaultLanguage(response.payload.waiverDocumentList)
            yield put({
                type: LANGUAGE_SETTINGS.SET,
                payload: languageSettings
            })
            yield put({
                type: WAIVER_LIST.SUCCESS,
                payload: response.payload.waiverDocumentList
            })
            yield put({
                type: FORM_FIELDS.SUCCESS,
                payload: response.payload.formFields
            })
            yield put({
                type: EVENTS.SET,
                payload: response.payload.events
            })
            yield put({
                type: LOADED.SUCCESS
            })
        } else {
            yield put({
                type: WAIVER_LIST.FAIL,
                payload: response
            })
            yield put({
                type: FORM_FIELDS.FAIL,
                payload: response
            })
            yield put({
                type: UI.ERROR.SUCCESS,
                payload: response.message
            })
        }
    } catch (error) {
        yield put({
            type: WAIVER_LIST.FAIL,
            payload: error
        })
    }
}

/**
 * Works if we are opening WaiverSign using session endpoint "/session/:sessionId"
 *
 * @function identifySigning
 * @param {Array} personInfoList - all persons for signing waiver
 * @return {string} person type for signing
 *
 */

function identifySigning(personInfoList, guardianInfo){
    let signing
    const minorCount = personInfoList.filter(p => !p.isAdult).length
    if (personInfoList.length === 1 && !minorCount) {
        signing = 'myself'
    } 
    else if (personInfoList.length === minorCount) {
        signing = 'minors'
    }
    else if (personInfoList.length !== minorCount) {
        signing = 'both'
    }

    return signing
}

/**
 * Check if there is a guardian
 * Works if we are opening WaiverSign using session endpoint "/session/:sessionId"
 *
 * @function identifyGuardian
 * @param {personInfoList} - all persons for signing waiver
 * @return {boolean} - is guardian
 *
 */

function identifyGuardian(personInfoList) {
    let isGuardian = true
    personInfoList.forEach(personType => {
        if(personType.isAdult) {
            isGuardian = false
        }
    })
    return isGuardian
}

/**
 *
 * Works if we are opening WaiverSign using session endpoint "/session/:sessionId"
 *
 * @function insertDataField
 * @param {Object} person - personInfo or guardianInfo
 * @param {Object} formFields - standard and custom document fields
 * @param {Object} additionalData - countries and states
 * @return {Object} data for form fields
 *
 */

function insertDataField(person, formFields, additionalData) {
    const data = {}
    Object.keys(person).forEach((key, idx) => {
        if (key === 'email') {
            data['Email'] = person[key]
        }
        if (key === 'title') {
            data['Title'] = person[key]
        }
        if (key === 'birthDate') {
            data['Date of Birth'] = person[key] ? moment(person[key]).utc().format('MM/DD/YYYY') : null
        }
        if (key === 'firstName') {
            data['Legal First Name'] = person[key]
        }
        else if (key === 'lastName') {
            data['Last Name'] = person[key]
        }
        else if (key === 'middleName') {
            data['Middle Name'] = person[key]
        }
        else if (key === 'nickName') {
            data['Nickname'] = person[key]
        }
        else if (key === 'gender') {
            data['Gender'] = person[key].toUpperCase()
        }
        else if (key === 'organization') {
            data['Organization'] = person[key]
        }
        else if (key === 'phone') {
            data['Phone'] = person[key]
        }
        else if (key === 'streetAddress') {
            data['Street Address'] = person[key]
        }
        else if (key === 'city') {
            data['City'] = person[key]
        }
        else if (key === 'postalCode') {
            data['Postal Code'] = person[key]
        }
        else if (key === 'state') {
            if ((!person.country || (person.country && (person.country !== 'CAN' && person.country !== 'USA'))) && typeof person[key] === 'object') {
                data['State'] = person[key].name
            } else {
                data['State'] = {
                    name: person[key].name,
                    abbreviation: person[key].abbreviation
                }
            }
        }
        else if (key === 'country') {
            for (let idx = 0; idx < additionalData.countries.length; idx += 1) {
                if (additionalData.countries[idx].alpha3 === person[key]) {
                    data['Country'] = additionalData.countries[idx]
                    break
                }
            }
        }
        else if (key === 'customFieldList' && person[key]) {
            data['customFieldList'] = {}
            person[key].forEach(field => {
                formFields.custom.forEach(customField => {
                    if (field.name === customField.name && customField.fieldType === 'checkbox') {
                        data['customFieldList'][field.name] = field.value.split(',')
                    }
                })
                if (!data['customFieldList'][field.name]) {
                    data['customFieldList'][field.name] = field.value
                }
            })
        }
        else if (key === 'sendEmail') {
            data['sendEmail'] = person[key]
        }
        else if (key === 'sendSms') {
            data['sendSms'] = person[key]
        }
        else if (key === 'identification' && person[key] && person[key].type && person[key].number) {
            data['Identification'] = person[key]
        }
        else if (key === 'isAdult'){
            data['isAdult'] = person[key]
        }
        else if (key === 'referenceId'){
            data['referenceId'] = person[key]
        }
    })
    return data
}

/**
 * Works if we are opening WaiverSign using session endpoint "/session/:sessionId"
 *
 * @function parseSession
 * @param {Object} signingSession - documentIdList, personInfoList, guardianInfo and partialNotification
 * @param {Object} formFields - standard and custom document fields
 * @param {Object} additionalData - countries and states
 * @return {Array} - person info
 *
 */

function parseSession(signingSession, formFields, additionalData) {
    const { personInfoList, guardianInfo } = signingSession
    const personInfo = []
    
    personInfoList.map(person => {
        personInfo.push(insertDataField(person, formFields, additionalData))
        return true
    })

    const isGuardian = identifyGuardian(personInfoList)
    if (isGuardian) {
        const data = insertDataField(guardianInfo, formFields, additionalData)
        data['isGuardian'] = true
        personInfo.push(data)
    }
    return personInfo
}

/**
 * Works if we are opening WaiverSign using session endpoint "/session/:sessionId"
 *
 * @generator
 * @function loadBySessionId
 * @param {Object} location @see {@link https://reacttraining.com/react-router/core/api/Route/location-object}
 * @param {string} type - endpoint type
 * @param {string} ids - session Ids
 *
 */

function* loadBySessionId(location, type, ids) {
    const state = yield select()

    const {
        documentIdList,
        personInfoList,
        guardianInfo,
        callbackUrl,
        returnUrl,
        partialNotification,
        referenceId,
        languageSettings
    } = state.signingSession
  
    const response = yield call(fetchGet, `additional/information`)
   
    yield put({
        type: ADDITIONAL_INFORMATION.COUNTRIES.SUCCESS,
        payload: response.payload
    })

    const additionalData = response.payload

    yield put({
        type: LOADING.SUCCESS
    })
    const signingProcessInformation = {
        partialNotification: partialNotification,
        returnUrl: `${window.location.origin}/doc/${documentIdList.join(',')}`,
        beginSigningUrl: `/doc/${documentIdList.join(',')}`,
        referenceId
    }

    if (callbackUrl) {
        signingProcessInformation.callbackUrl = callbackUrl
    }
    if (returnUrl) {
        signingProcessInformation.returnUrl = returnUrl
    }
    const query = queryString.parse(location.search)
    if (query.partialNotification === 'false') {
        signingProcessInformation.partialNotification = false
    }
    if (query.kioskMode) {
        signingProcessInformation.kioskMode = query.kioskMode
    }
    if (query.referenceId) {
        signingProcessInformation.referenceId = query.referenceId
    }
    if (query.callbackUrl) {
        signingProcessInformation.callbackUrl = query.callbackUrl
    }
    if (query.returnUrl) {
        signingProcessInformation.returnUrl = query.returnUrl
    }
    if (query.eventName) {
        signingProcessInformation.eventName = query.eventName
    }
    if (query.eventDate) {
        signingProcessInformation.eventDate = query.eventDate
    }
    if (query.event && query.event === 'none') {
        signingProcessInformation.noEvent = true
    }

    yield put({
        type: SIGNING.PROCESS.SUCCESS,
        payload: signingProcessInformation
    })

    const signing = identifySigning(personInfoList, guardianInfo) 

    try {
        const response = yield call(fetchPost, `doc/form`, {
            personInfoList,
            documentIdList,
            signing,
        })

        if (response.success) {
            const personInfo = parseSession(state.signingSession, response.payload.formFields, additionalData)
            const languageSetting = getDefaultLanguage(response.payload.waiverDocumentList, languageSettings)
            yield put({
                type: LANGUAGE_SETTINGS.SET,
                payload: languageSetting
            })
            yield put({
                type: PERSON_INFO.SUCCESS,
                payload: personInfo
            })
            yield put({
                type: WAIVER_LIST.SUCCESS,
                payload: response.payload.waiverDocumentList
            })
            yield put({
                type: FORM_FIELDS.SUCCESS,
                payload: response.payload.formFields
            })
            yield put({
                type: LOADED.SUCCESS
            })
        } else {
            yield put({
                type: WAIVER_LIST.FAIL,
                payload: response
            })
            yield put({
                type: FORM_FIELDS.FAIL,
                payload: response
            })
            yield put({
                type: UI.ERROR.SUCCESS,
                payload: response.message
            })
        }
    } catch (error) {
        yield put({
            type: WAIVER_LIST.FAIL,
            payload: error
        })
    }
}

/**
 * Load additional information
 * worker Saga: will be fired on ADDITIONAL_INFORMATION.REQUEST actions
 *
 * @generator
 * @function loadAdditionalInformation
 * @param {Object} action - action type and payload
 * @param {string} action.type - ADDITIONAL_INFORMATION.REQUEST
 * @param {object} action.payload.location @see {@link https://reacttraining.com/react-router/core/api/Route/location-object}
 * @param {object} action.payload.match @see {@link https://reacttraining.com/react-router/core/api/match}
 * @param {string} action.payload.match.params.type - endpoint
 * @param {string} action.payload.match.params.ids - documments Ids
 *
 * @see {@link https://github.com/redux-saga/redux-saga#sagasjs}
 *
 */

function* loadAdditionalInformation(action) {

    const { location, match } = action.payload

    if (match.params.type === 'doc') {
        yield loadBydocumentIds(location, match)
    } else if (match.params.type === 'session') {
        const { type, ids, entityId } = match.params
        yield loadBySessionId(location, type, ids, entityId)
    }
}

/**
 * Load city and state after adding postal Code field from Zippopotam service
 * Example: `https://api.zippopotam.us/:alpha2Country/:postalCode
 * worker Saga: will be fired on ADDITIONAL_INFORMATION.POSTAL_CODE.REQUEST actions
 *
 * @generator
 * @function loadPostalCode
 * @param {Object} action - action type and payload
 * @param {string} action.type - ADDITIONAL_INFORMATION.POSTAL_CODE.REQUEST
 * @param {string} action.payload - person type
 *
 * @see {@link https://github.com/redux-saga/redux-saga#sagasjs}
 * @see {@link http://www.zippopotam.us/}
 *
 */

function* loadPostalCode(action){
    const state = yield select()
    const error = {}
    const formName = 'CollectParticipantInfoForm'
    const activeInput = action.payload
    let alpha2Country, postalCode, response,countryName, country, minor

    const persons = state.form.CollectParticipantInfoForm.values

    yield put(startAsyncValidation(formName))

    for (const personType of Object.keys(persons)) {

        if (personType !== 'minors') {
            error[personType] = {}
            if (persons[personType].Country && persons[personType]['Postal Code']) {

                country = persons[personType].Country
                if (typeof country === 'string') {
                    countryName = JSON.parse(country).name
                    alpha2Country = JSON.parse(country).alpha2
                } else {
                    countryName = country.name
                    alpha2Country = country.alpha2
                }
                postalCode = persons[personType]['Postal Code']
                let postalCodeCopy = postalCode.toString()

                if (alpha2Country === 'US' || alpha2Country === 'CA') {
                    if (alpha2Country === 'CA') {
                        if (postalCode.length > 3) {
                            postalCodeCopy = postalCodeCopy.slice(0, 3)
                        }
                    } 

                    response = yield call(getPostalCode, { alpha2Country, postalCode: postalCodeCopy })
                    
                    if (!response.status) {
                        if (response && response.places) {
                            if (!persons[personType]['City']) {
                                yield put(change(formName, `${personType}.City`, response.places[0]['place name']))
                            }
                            yield put(change(formName, `${personType}.State`, JSON.stringify({
                                name: response.places[0]['state'],
                                abbreviation: response.places[0]['state abbreviation']
                            })))
                        } else {
                            error[personType] = {'Postal Code': {text: fields.POSTAL_CODE_FOR, countryName}}
                            yield put(change(formName, `${activeInput}.State`, ''))
                        }
                    }
                }
            }
        } else {
            error[personType] = []
            for (const idx in persons[personType]) {
                minor = persons[personType][idx]

                error[personType][idx] = {'Postal Code': ''}
                if (minor.Country && minor['Postal Code']) {
                    if (typeof minor.Country === 'string') {
                        countryName = JSON.parse(minor.Country).name
                        alpha2Country = JSON.parse(minor.Country).alpha2
                    } else {
                        countryName = minor.Country.name
                        alpha2Country = minor.Country.alpha2
                    }
                    postalCode = minor['Postal Code']
                    let postalCodeCopy = postalCode.toString()

                    if (alpha2Country === 'US' || alpha2Country === 'CA') {
                        if (alpha2Country === 'CA') {
                            if (postalCode.length > 3) {
                                postalCodeCopy = postalCodeCopy.slice(0, 3)
                            }
                        } 

                        response = yield call(getPostalCode, { alpha2Country, postalCode: postalCodeCopy })
                    
                        if (!response.status) {
                            if (response.places) {
                                if (!minor['City']) {
                                    yield put(change(formName, `${personType}[${idx}].City`, response.places[0]['place name']))
                                }
                                yield put(change(formName, `${personType}[${idx}].State`, JSON.stringify({
                                    name: response.places[0]['state'],
                                    abbreviation: response.places[0]['state abbreviation']
                                })))
                                error[personType][idx] = {'Postal Code': ''}
                            } else {
                                error[personType][idx] = {'Postal Code': {text: fields.POSTAL_CODE_FOR, countryName}}
                                yield put(change(formName, `${personType}[${idx}].State`, ''))
                            }
                        }
                    }
                }
            }
        }
        if (!persons[personType].Country && persons[personType]['Postal Code']) {
            yield put(change(formName, `${activeInput}.State`, ''))
        }
    }
    yield put(stopAsyncValidation(formName, error))
}

function getDefaultLanguage(documentsList, languageSetting) {
    const languageSettings = {
        default: 'en',
        availableLanguages: [],
        current: 'en'
    }

    documentsList.forEach(document => {
        if(document.showDuringSigning) {
            Object.keys(document.showDuringSigning).forEach(lang => {
                if (document.showDuringSigning[lang] && languageSettings.availableLanguages.indexOf(lang) === -1) {
                    languageSettings.availableLanguages.push(lang)
                }
            })
        }
    })
    if(languageSettings.availableLanguages.length === 1) {
        languageSettings.default = languageSettings.availableLanguages[0]
    } else {
        languageSettings.default = 'en'
    }
    //now there are default language and available languages is languageSettings based on showDuringSigning flag of documents
    languageSettings.current = languageSetting && languageSetting.current ? languageSetting.current : languageSettings.default
    return languageSettings
}

/**
 *
 * Starts loadAdditionalInformation on each dispatched ADDITIONAL_INFORMATION.REQUEST action.
 * Starts loadPostalCode on each dispatched ADDITIONAL_INFORMATION.POSTAL_CODE.REQUEST action.
 *
 * We can use takeEvery or takeLatest
 *  takeEvery - Allows concurrent fetches of user
 *  takeLatest - Does not allow concurrent fetches
 *
 * @generator
 * @function additional
 * @see {@link https://github.com/redux-saga/redux-saga#sagasjs}
 *
 */

export function* additional() {
    yield [
        takeLatest(ADDITIONAL_INFORMATION.REQUEST, loadAdditionalInformation),
        takeLatest(ADDITIONAL_INFORMATION.POSTAL_CODE.REQUEST, loadPostalCode)
    ]
}
