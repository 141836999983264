import React, { Component } from 'react'
import {connect} from 'react-redux'
import { Message, Image, Responsive } from 'semantic-ui-react'

class DisabledBusinessInfo extends Component {

    render() {
        const { businessEntity } = this.props
        return (
            <div className='ui padded segment'>
                <Responsive as={'div'} className="ui two column middle aligned grid" minWidth={768}>
                    <div className="thirteen wide column">
                        {businessEntity && businessEntity.images && businessEntity.images.logoSrc ? <Image src={businessEntity.images.logoSrc} id="logo" /> : ''}
                    </div>
                </Responsive>
                <Responsive as={'div'} className="ui two column middle aligned grid" maxWidth={767}>
                    <div className="column">
                        {businessEntity && businessEntity.images && businessEntity.images.logoSrc ? <Image src={businessEntity.images.logoSrc} size='small' /> : ''}
                    </div>
                </Responsive>
                <Message warning>
                    <p>This account has been disabled. Please contact {businessEntity.name} for more information.</p>
                </Message>
            </div>
        )
    }
}

export default connect(
    state => ({
        businessEntity: state.additionalInformation.businessEntity || {}
    })
)(DisabledBusinessInfo)
