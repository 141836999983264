import React, { Component } from 'react'
import { Modal, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { createAction } from '../../sagas/createAction'
import {SIGNING, UI} from '../../constants'
import getTranslator from '../../multilingualism/getTranslator'
import fields from '../../multilingualism/fields'


class ConfirmationModal extends Component {

    render() {
        const { showModal, backToEditInformation, dispatch, languageSettings, waiverLanguage } = this.props
        const translate = getTranslator(languageSettings, waiverLanguage)
        return (
            <Modal size="small" open={!!showModal}>
                <Modal.Header>{translate(fields.EDIT_INFORMATION)}</Modal.Header>
                <Modal.Content>
                    <p>{translate(fields.EDIT_MODAL_CONTENT)}</p>
                </Modal.Content>
                <Modal.Actions className="txt-left">
                    <Button id="editButton" primary type="button" onClick={() => dispatch(backToEditInformation(showModal.location))}>{translate(fields.EDIT)}</Button>
                    <Button id="closeButton" basic color='blue' type="button" onClick={() => dispatch({ type: UI.MODAL.FAIL })}>{translate(fields.CLOSE)}</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default connect(
    state => ({
        showModal: state.ui.confirmationModal,
        languageSettings: state.languageSettings,
        waiverLanguage: state.waiverLanguage
    }),
    dispatch => ({
        dispatch,
        backToEditInformation: createAction(SIGNING.INFO.CLEAR),
    })
)(ConfirmationModal)
