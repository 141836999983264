import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormSection, Field, formValueSelector, change } from 'redux-form'
import renderSelectField from '../Custom/selectField'
import renderDatepickerField from '../Custom/datepickerField'
import {Header, Segment, Label} from 'semantic-ui-react'
import { createAction } from '../../sagas/createAction'
import { EVENTS } from '../../constants'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/en-gb'
import getTranslator from '../../multilingualism/getTranslator'
import fields from '../../multilingualism/fields'

class EventForm extends Component {
    constructor() {
        super()
        this.state = {
            eventNameOptions: [],
            eventDateOptions: [],
            hasNoFutureDates: false
        }
        this.toggleRequired = this.toggleRequired.bind(this)
    }

    toggleRequired(fieldName, isRequired) {
        const {
            formFields
        } = this.props

        if(formFields.event && formFields.event.length) {
            formFields.event.forEach(field => {
                if(field.name === fieldName) {
                    field.required = isRequired
                }
            })
        }
    }

    componentDidMount() {
        const { signingProcess, events, change } = this.props
        let eventNamePrefilled = '', eventDatePrefilled = '', selectedEvent
        if (signingProcess.eventForSigning) {
            signingProcess.eventForSigning.eventName && change('CollectParticipantInfoForm', 'event.eventName', signingProcess.eventForSigning.eventName)
            signingProcess.eventForSigning.eventDate && change('CollectParticipantInfoForm', 'event.eventDate', signingProcess.eventForSigning.eventDate)
        }
        
        const eventNameOptions = events.filter(event => {
            let isValid = false
            if (event.dateOption === 'NO_DATE' || event.dateOption === 'RANDOM_DATE') {
                return true
            }
            event.dateList.forEach(date => {
                if (!isValid) {
                    isValid = moment().diff(moment(date)) <= 0
                }
            })
            return isValid
        }).map(event => {
            if (event.name === signingProcess.eventName) {
                eventNamePrefilled = event.name
                selectedEvent = event
                change('CollectParticipantInfoForm', 'event.eventName', event.name)
            }
            return {
                key: event._id,
                text: event.name,
                value: event.name
            }
        })

        eventNameOptions.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)

        if (signingProcess.eventDate) {
            change('CollectParticipantInfoForm', 'event.eventDate', signingProcess.eventDate)
            eventDatePrefilled = signingProcess.eventDate
        }

        this.eventNamePrefilled = eventNamePrefilled
        this.eventDatePrefilled = eventDatePrefilled

        this.setState({
            selectedEvent,
            eventNameOptions: [
                ...eventNameOptions
            ]
        })
    }
    componentWillReceiveProps(nextProps) {
        const { events } = this.props
        const dateNow = moment().format('YYYY-MM-DD')
        let selectedEvent = {}, eventDateOptions = [], hasNoFutureDates = false

        events.forEach(event => {
            if (event.name === nextProps.eventName || event.name === this.eventNamePrefilled) {
                selectedEvent = event
            }
        })

        if (selectedEvent.dateList) {
            eventDateOptions = selectedEvent.dateList
                .filter(option => {
                    const eventDate = moment.utc(option).format('YYYY-MM-DD')
                    return moment(dateNow).diff(moment(eventDate), 'days') <= 0
                })
                .sort((a, b) => new Date(a) - new Date(b))
            if (eventDateOptions.length === 0 && selectedEvent.dateOption === 'SPECIFIC_DATE') {
                hasNoFutureDates = true
            }
        }
        if ((!this.state.selectedEvent && Object.keys(selectedEvent).length) ||
            (this.state.selectedEvent && Object.keys(selectedEvent).length && this.state.selectedEvent._id !== selectedEvent._id)) {
            this.setState({
                selectedEvent,
                eventDateOptions,
                hasNoFutureDates
            })
        }
    }
    renderDateField() {
        const { selectedEvent, eventDateOptions } = this.state
        const { languageSettings, waiverLanguage } = this.props
        const translate = getTranslator(languageSettings, waiverLanguage)
        moment.locale(languageSettings.current)
        if (!selectedEvent || selectedEvent.dateOption === 'NO_DATE') {
            if(selectedEvent && selectedEvent.dateOption === 'NO_DATE') {
                this.toggleRequired('eventDate', false)
            }
            return null
        }

        if (this.eventDatePrefilled) {
            this.toggleRequired('eventDate', false)
            return <div className="field" id="eventDate">
                <label>{translate(fields.EVENT_DATE)}</label>
                <div>{moment.utc(this.eventDatePrefilled).format('dddd, MMMM D, YYYY')}</div>
            </div>
        }

        if (selectedEvent.dateOption === 'SPECIFIC_DATE') {
            return <Field 
                name="eventDate"
                id="eventDate"
                component={renderSelectField}
                label={translate(fields.EVENT_DATE)}
                placeholder={translate(fields.EVENT_DATE_PLACEHOLDER)}
                translate={field => translate(field)}
                required={true}
                options={[
                    { key: '', text: translate(fields.EVENT_DATE_PLACEHOLDER), value: '' },
                    ...eventDateOptions.map((date, idx) => ({
                        key: idx,
                        text: moment.utc(date).format('dddd, MMMM D, YYYY'),
                        value: date
                    }))
                ]}
            />
        } else {

        }

        if (selectedEvent.dateOption === 'RANDOM_DATE') {
            return <Field
                name="eventDate"
                id="eventDate"
                label={translate(fields.EVENT_DATE)}
                component={renderDatepickerField}
                placeholder={translate(fields.EVENT_DATE_PLACEHOLDER)}
                translate={field => translate(field)}
                required={true}
                minDate={moment.utc()}
            />
        }
        moment.locale('en')
    }
    render() {
        const { eventNameOptions, hasNoFutureDates } = this.state
        const { change, businessEntity, languageSettings, waiverLanguage} = this.props
        const translate = getTranslator(languageSettings, waiverLanguage)
        if(this.eventNamePrefilled) {
            this.toggleRequired('eventName', false)
        }
        return (
            <FormSection name="event">
                <div className="row margin-top">
                    <Segment padded id="eventForm">
                        <Header as="h2" dividing>{translate(fields.EVENT_HEADER)}</Header>
                        {this.eventNamePrefilled ?
                            <div className="field" >
                                <label>{translate(fields.EVENT_NAME)}</label>
                                <div>{this.eventNamePrefilled}</div>
                            </div> :
                            <Field 
                                id={`eventName`}
                                name="eventName"
                                component={renderSelectField}
                                label={translate(fields.EVENT_NAME)}
                                placeholder={translate(fields.EVENT_NAME_PLACEHOLDER)}
                                translate={field => translate(field)}
                                required={true}
                                options={[ { key: '', text: translate(fields.EVENT_NAME_PLACEHOLDER), value: '' }, ...eventNameOptions]}
                                onSelect={() => change('CollectParticipantInfoForm', 'event.eventDate', '')}
                            />
                        }
                        {this.renderDateField()}
                        {hasNoFutureDates && 
                            <Label 
                                pointing 
                                basic 
                                color='red' 
                                content={`No future dates have been created for this event. Please contact ${businessEntity.name}.`}
                            />
                        }
                    </Segment>
                </div>
            </FormSection>
        )
    }
}

const selector = formValueSelector('CollectParticipantInfoForm')
export default connect(
    state => ({
        events: state.events,
        signingProcess: state.signingProcess,
        eventName: selector(state, 'event.eventName'),
        eventDate: selector(state, 'event.eventDate'),
        businessEntity: state.additionalInformation.businessEntity,
        languageSettings: state.languageSettings,
        waiverLanguage: state.waiverLanguage,
        formFields: state.formFields
    }),
    {
        change,
        registerEventDateField: createAction(EVENTS.REGISTER_EVENT_DATE_FIELD),
        removeEventDateField: createAction(EVENTS.REMOVE_EVENT_DATE_FIELD)
    }
)(EventForm)
