import React from 'react'
import { Form, Label } from 'semantic-ui-react'
import {connect} from 'react-redux'

class SelectField extends React.Component {
    render() {
        const {
            required,
            input,
            options,
            placeholder,
            description,
            label,
            meta: { touched, error },
            onSelect,
            translate,
            id
        } = this.props

        const searchParams = new URLSearchParams(document.location.search)

        const prefillData = searchParams.get(id)

        if(input.value === '' && prefillData && id !== 'eventName'){
            input.onChange(prefillData)
        }

        return (
            <Form.Field required={required}>
                <label htmlFor={id}>{label}</label>
                {
                    description && <div className='description'>{description}</div>
                }
                <div className={`field ${!!touched && !!error ? 'error' : ''}`}>
                    <select
                        {...input}
                        id={id}
                        placeholder={placeholder || label}
                        onChange={({target}) => {
                            if (input.name === 'minorsCount') {
                                input.onChange(parseInt(target.value, 10))
                            } else {
                                input.onChange(target.value)
                            }
                            if (input.value !== target.value) {
                                onSelect && onSelect()
                            }
                        }}
                        required={required}
                        className='ui dropdown'
                        aria-label={label}
                    >
                        {
                            options.map(option => (<option key={option.key} value={option.value}>{option.text}</option>))
                        }
                    </select>
                </div>
                {touched && (error && <Label pointing basic color='red' id={`error_${label.replace(/ /g, '')}`}> {translate(error)} </Label>)}
            </Form.Field>
        )
    }
}

export default connect(
    state => ({
    }),{}
)(SelectField)
