import React from 'react'
import { Form, Label } from 'semantic-ui-react'
import { connect } from 'react-redux'

class InputField extends React.Component {
    render() {
        const { required, placeholder, description, input, label, type, icon, autoComplete, meta: { touched, error }, translate, id } = this.props

        const searchParams = new URLSearchParams(document.location.search)
        const prefillData = searchParams.get(id)

        if(prefillData && input.value === ''){
            input.onChange(prefillData)
        }

        return (
            <Form.Field required={required}>
                <label htmlFor={id}>{label}</label>
                {
                    description && <div className='description'>{description}</div>
                }
                <Form.Input id={id} autoComplete={autoComplete} type={type} {...input} placeholder={placeholder || label}
                    onChange={(param, data) => input.onChange(data.value)} required={required}
                    error={!!touched && !!error}/>
                {touched && (error &&
                    <Label pointing basic color='red' id={`error_${label.replace(/ /g, '')}`}>{ error.text ? translate(error.text, { count: error.value }): translate(error)} </Label>)}
            </Form.Field>
        )
    }
}

export default connect(
    state => ({
    }),{}
)(InputField)