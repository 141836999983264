import { PARTICIPANT_OPTIONS } from '../constants'

/**
 * Set participantOptions' data into store.
 *
 * @function participantOptions
 *
 * @param {Object} state - Current value of the participantOptions field of the store (default value is an empty object)
 *
 * @param {Object} action - Dispatched action with one required field - "type" and one optional - "payload"
 * @returns {Object} New value of participantOptions
 *
 */

export const participantOptions = (state = {}, action) => {
    switch (action.type) {
        case PARTICIPANT_OPTIONS.SUCCESS:
            return action.payload
        default:
            return state
    }
}
