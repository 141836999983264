import React from 'react'
import { connect } from 'react-redux'
import { Segment } from 'semantic-ui-react'

class AdditionalInformation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            documentForSigning: {}
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.documentForSigning) {
            this.setState({
                documentForSigning: nextProps.documentForSigning
            })
        }
    }

    render() {
        const { wsImage, additionalInformation } = this.state.documentForSigning
        const { current } = this.props.languageSettings
        // const show = wsImage || additionalInformation

        let content = ''

        if (additionalInformation) {
            content = additionalInformation[current].content
        }

        if (wsImage && content.length) {
            return <Segment className="very padded">
                <div className="ui two column stackable grid">
                    {wsImage ? <div className="four wide centered column">
                        <img src={wsImage.src} className="ui centered image logo" id='additionalInfoImage' onerror="this.style.display='none'" />
                    </div> : null}
                    {content.length ? <div className="twelve wide column">
                        <p dangerouslySetInnerHTML={{__html: content}} />
                    </div> : null}
                </div>
            </Segment>
        }
        else if(wsImage) {
            return <Segment className="very padded">
                <div className="ui two column stackable grid">
                    <div className="four wide centered column">
                        <img src={wsImage.src} className="ui centered image logo" id='additionalInfoImage' onerror="this.style.display='none'"/>
                    </div>
                </div>
            </Segment>
        }
        else if(content.length) {
            return <Segment className="very padded">
                <div className="ui stackable grid">
                    {content.length ? <div className="sixteen wide column">
                        <p dangerouslySetInnerHTML={{__html: content}} />
                    </div> : null}
                </div>
            </Segment>
        } else {
            return null
        }

    }
}

export default connect(
    state => ({
        documentForSigning: state.documentForSigning,
        languageSettings: state.languageSettings
    })
)(AdditionalInformation)
