import { put, takeLatest } from 'redux-saga/effects'
import { MINORS_DROPDOWN, WAIVER_LIST } from '../constants'
import { push } from 'react-router-redux'

/**
 * 
 * Verification of document Signing Options
 * 
 * @generator
 * @function changeMinorsDropdown
 * @param {string} action.type - MINORS_DROPDOWN.REQUEST
 * @param {boolean} action.payload 
 * 
 *  action.payload = true if documents can only be signed for minors, documents can be signed for both adults and minors
 *  action.payload = false if documents can only be signed for adults
 */

function* changeMinorsDropdown(action) {
    yield put({
        type: MINORS_DROPDOWN.SUCCESS,
        payload: action.payload
    })
}

/**
 * 
 * Load signing options and minors count
 * 
 * @generator
 * @function submitParticipantSelection
 * @param {object} action - { data: { signing, minorsCount }, match, location }
 * @param {string} action.payload.data.signing - signing options: minors, myself or both
 * @param {number} action.payload.data.minorsCount - minors count
 * @param {string} action.type - WAIVER_LIST.REQUEST
 * @param {Object} action.payload.location @see {@link https://reacttraining.com/react-router/core/api/Route/location-object}
 * @param {Object} action.payload.match @see {@link https://reacttraining.com/react-router/core/api/match}
 * 
 */

function* submitParticipantSelection(action) {
    const { data: { signing, minorsCount }, match, location } = action.payload
    const url = `${match.url}/info/${signing}/${signing === 'myself' ? 1 : minorsCount}${location.search}`
    yield put(push(url))
} 

/**
 * 
 * Starts changeMinorsDropdown on each dispatched MINORS_DROPDOWN.REQUEST action.
 * Starts submitParticipantSelection on each dispatched WAIVER_LIST.REQUEST action.
 * 
 * We can use takeEvery or takeLatest
 *  takeEvery - Allows concurrent fetches of user
 *  takeLatest - Does not allow concurrent fetches
 * 
 * @generator
 * @function selectParticipant
 * @see {@link https://github.com/redux-saga/redux-saga#sagasjs}
 * 
 */

export function* selectParticipant(){
    yield [
        takeLatest(MINORS_DROPDOWN.REQUEST, changeMinorsDropdown),
        takeLatest(WAIVER_LIST.REQUEST, submitParticipantSelection)
    ]
}