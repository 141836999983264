import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import configureStore from './store'
import { Provider } from 'react-redux'
import Main from './components/Main'
import Header from './components/Header'
import Footer from './components/Footer'
import ErrorPopup from './components/Ui/ErrorPopup'
import ConfirmationModal from './components/Ui/ConfirmationModal'

/**
 * A React component that holds all other components and is the point of entry.
 */
class App extends Component {
    render() {
        const store = configureStore()
        return (
            <Provider store={store}>
                <ConnectedRouter store={store} history={store.history}>
                    <div>
                        <div className="main-header">
                            <Switch>
                                <Route path="/e/:entityId/:type/:ids" component={Header} />
                                <Route path="/:type/:ids" component={Header} />
                            </Switch>
                        </div>
                            <Switch>
                                <Route path="/e/:entityId/:type/:ids" component={Main} />
                                <Route path="/:type/:ids" component={Main} />
                            </Switch>
                        <ErrorPopup />
                        <ConfirmationModal />
                        <footer>
                            <Footer />
                        </footer>
                    </div>
                </ConnectedRouter>
            </Provider>
        )
    }
}

export default App

