import React, { Component } from 'react'
import { Form, Label } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { createAction } from '../../sagas/createAction'
import { ADDITIONAL_INFORMATION } from '../../constants'
import getTranslator from '../../multilingualism/getTranslator'

class PostalCodeField extends Component {
    render() {
        const {
            required,
            input,
            placeholder,
            label,
            getPostalCodeData,
            autoComplete,
            meta: { touched, error },
            languageSettings,
            waiverLanguage,
            id
        } = this.props
        const translate = getTranslator(languageSettings, waiverLanguage)
        const errorText = error && error.countryName ? translate(error.text, { country: error.countryName}) : translate(error)
        const searchParams = new URLSearchParams(document.location.search)
        const prefillData = searchParams.get(id)

        if(prefillData && input.value === ''){
            input.onChange(prefillData)
        }

        return (
            <Form.Field>
                <Form.Input
                    {...input}
                    id={id}
                    autoComplete={autoComplete}
                    label={label}
                    type="text"
                    placeholder={placeholder || label}
                    onBlur={() => {
                        input.onBlur(input.value)
                        getPostalCodeData(input.name.split('.')[0])
                    }}
                    required={required}
                    error={!!touched && !!error}
                />
                {touched && (error && <Label pointing basic color='red' id={`error_${id}`}>{errorText}</Label>)}
            </Form.Field>
        )
    }
}

export default connect(
    state => ({
        languageSettings: state.languageSettings,
        waiverLanguage: state.waiverLanguage
    }),{
        getPostalCodeData: createAction(ADDITIONAL_INFORMATION.POSTAL_CODE)
    }
)(PostalCodeField)
