import React, { Component } from 'react'
import { connect } from 'react-redux'
import PersonDocuments from './personDocuments'
import {Button, Responsive, List, Message} from 'semantic-ui-react'
import { createAction } from '../../sagas/createAction'
import { SIGNING, CANCEL } from '../../constants'
import { push } from 'react-router-redux'
import getTranslator from '../../multilingualism/getTranslator'
import fields from '../../multilingualism/fields'
import LanguageDropdown from '../Ui/LanguageDropdown'
import queryString from 'query-string'

class DocumentsSignature extends Component {

    getAvailableLanguage(waiver) {
        const {
            languageSettings
        } = this.props

        let documentLanguage;
        for(let key in waiver.showDuringSigning){
            if(waiver.showDuringSigning[key]){
                documentLanguage = key
                break;
            }
        }
        let isCurrentLanguageAvailable = false
        if (waiver[languageSettings.current].content) {
            if (waiver.initials.length) {
                waiver.initials.forEach(initial => {
                    if (initial[languageSettings.current].content) {
                        isCurrentLanguageAvailable = true
                    } else {
                        isCurrentLanguageAvailable = false
                    }
                })
            } else {
                isCurrentLanguageAvailable = true
            }
        }

        return isCurrentLanguageAvailable ? languageSettings.current : documentLanguage
    }

    componentDidMount() {
        const { cancel, personInfo, match, location, waiverList, setDocumentForSigning } = this.props
        if (!personInfo.length) {
            cancel({match, location})
        }
        const notSigned = waiverList.filter(waiver => !waiver.isSigned)
        if (notSigned.length && waiverList.length > notSigned.length) {
            setDocumentForSigning({ wsDoc: notSigned[0], url: match.url, location})
        }
    }
    handleSign = () => {
        const { waiverList, setDocumentForSigning, match, location } = this.props
        const notSigned = waiverList.filter(waiver => !waiver.isSigned)
        if (notSigned.length) {
            setDocumentForSigning({ wsDoc: notSigned[0], url: match.url, location})
        }
    }

    handleCancel = () => {
        const { signingProcess: { returnUrl }, cancel, match, location } = this.props
        returnUrl ? window.open(returnUrl, '_self') : cancel({match, location})
    }

    handleSuccess = () => {
        const { waiverList, signingProcess, submitAllDocument, match } = this.props
        if (signingProcess.partialNotification) {
            if (waiverList[0].isCustomRedirect) {
                window.open(waiverList[0].redirectUrl, '_self')
            } else {
                window.open(signingProcess.returnUrl, '_self')
            }
        } else {
            submitAllDocument(match.url)
        }
    }

    isSuccess = () => {
        const { waiverList } = this.props
        let isSuccess = !!waiverList.length
        waiverList.forEach(waiver => {
            if (!waiver.isSigned) {
                isSuccess = false
            }
        })
        return isSuccess
    }

    showUpsellMessage = () => {
        const { participantOptions, signingProcess } = this.props
        return participantOptions.showUpsellMessage && !signingProcess.kioskMode
    }

    renderHeader = translate => {
        const {personInfo, waiverList} = this.props
        let header = ''
        let waiversCount = 0
        if (this.isSuccess()) {
            header = translate(fields.SUCCESS)
        } else {
            personInfo.forEach(person => {
                if (person.isGuardian) {
                    return null
                }
                waiversCount += waiverList.length
            })
            header = translate(fields.REQUIRED_SIGNATURE, {}, waiversCount)
        }
        return header
    }

    renderWaiverParticipants = waiver => {
        const { personInfo } = this.props
        const waiverLanguage = this.getAvailableLanguage(waiver)

        return <div className="ui centered grid margin-top">
            <div className="eleven wide column">
                <h3 className="ui left aligned header">
            {waiver.name[waiverLanguage]}
        </h3>
            {
                // eslint-disable-next-line
                personInfo.map((person) => {
                    if(!person.isGuardian && person.signedWaivers[waiver._id] && person.signedWaivers[waiver._id].completedDate) {
                        return <Message positive className="half-padding">
                            <div className="ui two column left aligned grid">
                                <div className="column padding-none">
                                    <i className="file alternate outline icon"></i>{`${person['Legal First Name']} ${person['Last Name']}`}
                                </div>
                                <div className="right aligned column padding-none">
                                    <i className="check circle icon"></i>
                                </div>
                            </div>
                        </Message>
                    }
                })
            }
        </div>
        </div>
    }

    renderContent = translate => {
        const { personInfo, waiverList } = this.props
        const signingPersonNameList = []
        personInfo.forEach(person => {
            !person.isGuardian && signingPersonNameList.push(`${person['Legal First Name']} ${person['Last Name']}`)
        })
        return this.isSuccess()
            ? <div><p id="successMessage">{translate(fields.SUCCESS_INSTRUCTIONS)} <strong id="successEmail">{personInfo[0]['Email']}</strong>.</p>
                <div>{waiverList.map((waiver, idx) =>
                    <div key={idx} id={`document` + idx}>
                        {this.renderWaiverParticipants(waiver)}
                    </div>
                )}</div>
            </div>
        : <div id="warningMessage" className="ui mini warning message margin-top">{translate(fields.READ_PLEASE)}</div>
    }

    renderBtn = translate => {
        const { loading, signingProcess: { partialNotification } } = this.props
        const buttonText = translate(fields.BEGIN_SIGNING)
        return this.isSuccess() ?
            <div>
                <Button
                    id="signingButton"
                    className="big primary margin-top"
                    onClick={this.handleSuccess}
                    disabled={loading}
                    loading={loading}>
                    {partialNotification ? translate(fields.DONE) : 'Signing All'}
                </Button>
            </div>
            :
            <div>
                <Responsive as={'div'} minWidth={768}>
                    <Button className="ui primary button margin-top" id="beginSigningButton" size="big" onClick={this.handleSign}>{buttonText}</Button>
                    <Button className="ui secondary button margin-left margin-top" id="cancelButton" size="big" onClick={this.handleCancel}>{translate(fields.CANCEL)}</Button>
                </Responsive>
                <Responsive as={'div'} maxWidth={767}>
                    <Button className="ui primary button margin-top" id="beginSigningButton" size="big" onClick={this.handleSign}>{buttonText}</Button>
                    <Button className="ui secondary button" id="cancelButton" size="big" onClick={this.handleCancel}>{translate(fields.CANCEL)}</Button>
                </Responsive>
            </div>
    }

    render() {
        const { waiverList, personInfo, signingProcess, languageSettings, waiverLanguage } = this.props
        const translate = getTranslator(languageSettings, waiverLanguage)
        const showLanguageDropdown = languageSettings.availableLanguages.length > 1
        const upsellMessageInnerStyle = {margin: 0, 'line-height': '16px', 'font-size': '14px'}
        return (
                <div>
                    <Responsive as={'div'} className="ui center aligned grid padding-none" minWidth={768}>
                        {showLanguageDropdown ? <div className="ui row">
                            <div className="three wide centered column margin-bottom">
                                <LanguageDropdown/>
                            </div>
                        </div> : null }
                        <div className="twelve wide column">
                            <h1 id="documentsNumber" className="ui header">{this.renderHeader(translate)}</h1>
                            <List as="ul" size="big" className="centerList">
                            {personInfo.map((person, idx) => {
                                if (person.isGuardian) {
                                    return null
                                }
                                return this.isSuccess() ? null : <PersonDocuments key={idx} waiverList={waiverList} person={person} partialNotification={signingProcess.partialNotification} />
                            })}
                            </List>
                            {this.renderContent(translate)}
                            {this.renderBtn(translate)}
                            {this.isSuccess() && this.showUpsellMessage() && <div className='ui secondary segment footer-success-message mobile'>
                                <h4 className="ui center aligned header" style={{'margin': 0, 'font-size': '18px'}}>Do you use waivers or other similar documents?</h4>
                                <p style={upsellMessageInnerStyle}>
                                    Create and sign online waivers, online release of liability forms, rental agreements, consent forms, and more with WaiverSign.
                                </p>
                                <div style={{'padding-top': '16px'}}>
                                    <a href='https://www.waiversign.com/?utm_source=application-confirmation-page&utm_medium=waiversign-application&utm_campaign=does-your-business-use-waivers' target="_blank" style={{'text-decoration': 'none', 'color': '#EF9331'}}><strong>Sign Up Today!</strong></a>
                                </div>
                            </div>}
                        </div>
                    </Responsive>
                    <Responsive as={'div'} className="ui center aligned grid" maxWidth={767}>
                        {showLanguageDropdown ? <div className="ui row">
                            <div className="seven wide centered column">
                                <LanguageDropdown/>
                            </div>
                        </div> : null}
                        <div className="sixteen wide column">
                            <h1 className="ui header">{this.renderHeader(translate)}</h1>
                            {personInfo.map((person, idx) => {
                                if (person.isGuardian) {
                                    return null
                                }
                                return this.isSuccess() ? null : <PersonDocuments key={idx} waiverList={waiverList} person={person} partialNotification={signingProcess.partialNotification} />
                            })}
                            {this.renderContent(translate)}
                            {this.renderBtn(translate)}
                            {this.isSuccess() && this.showUpsellMessage() && <div className='ui secondary segment footer-success-message mobile'>
                                <h4 className="ui center aligned header" style={{'margin': 0, 'font-size': '18px'}}>Do you use waivers or other similar documents?</h4>
                                <p style={upsellMessageInnerStyle}>
                                    Create and sign online waivers, online release of liability forms, rental agreements, consent forms, and more with WaiverSign.
                                </p>
                                <div style={{'padding-top': '16px'}}>
                                    <a href='https://www.waiversign.com/?utm_source=application-confirmation-page&utm_medium=waiversign-application&utm_campaign=does-your-business-use-waivers' target="_blank" style={{'text-decoration': 'none', 'color': '#EF9331'}}><strong>Sign Up Today!</strong></a>
                                </div>
                            </div>}
                        </div>
                    </Responsive>

                </div>
        )
    }
}

export default connect(
    state => ({
        waiverList: state.waiverList,
        personInfo: state.personInfo,
        signingProcess: state.signingProcess,
        loading: state.loading,
        businessEntity: state.additionalInformation.businessEntity,
        languageSettings: state.languageSettings,
        waiverLanguage: state.waiverLanguage,
        participantOptions: state.participantOptions
    }),
    {
        push,
        cancel: createAction(CANCEL),
        done: createAction(SIGNING.DONE),
        setDocumentForSigning: createAction(SIGNING.INFO.SET),
        submitAllDocument: createAction(SIGNING.DOCUMENT.MULTI)
    }
)(DocumentsSignature)
