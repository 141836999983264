import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Button, Image, Dimmer, Loader, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import moment from 'moment'
//import PersonDocuments from '../DocumentsSignature/personDocuments'

class RevokeSignedWaiver extends PureComponent {
    handleSuccess = () => {
        const { signingProcess } = this.props
        if (signingProcess.partialNotification) {
            window.open(signingProcess.returnUrl, '_self')
        }
    }
    isSuccess = () => {
        const { revokeSignedWaiver } = this.props
        let isSuccess = false
        if (Object.keys(revokeSignedWaiver).length) {
            isSuccess = true
        }
        return isSuccess
    }
    render() {
        const { businessEntity, revokeSignedWaiver } = this.props
        const revokeSignedWaiverList = []
        this.isSuccess() && revokeSignedWaiver.map((signedWaiver, index) => {
            revokeSignedWaiverList.push(<div key={index} className="ui basic segment padding-none-left">
                <div className="ui stackable grid">
                    <h3 className="margin-none">
                    Revoking Signature for {signedWaiver.personInfo.firstName} {signedWaiver.personInfo.lastName}
                    </h3>
                    <div className="row padding-left">
                        <div className="nine wide column padding-none">
                            <Link to={`/revokedDocument/${signedWaiver.signedWaiverId}/view`} replace target="_blank">
                                <div className="ui warning message">
                                    <h4 className="ui header">
                                        {signedWaiver.waiverSign.name}
                                    </h4>
                                    <div className="ui items">
                                        <div className="item">
                                            <i className="warning file text outline huge icon ui desktop only" />
                                            <div className="content">
                                                <div className="header">
                                                {signedWaiver.personInfo.firstName} {signedWaiver.personInfo.lastName}
                                                </div>
                                                <div className="description margin-none">
                                                    {`Revoked on ${moment.utc(signedWaiver.revokeDate).format('MMMM DD, YYYY [at] h:mm:ssA UTC ')}`}
                                                </div>
                                                <div className="description margin-none">
                                                    { `Document Id # ${signedWaiver.waiverSign.documentId}`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>)
            return true
        })

        return (
            this.isSuccess() ?
                <section>
                <div className="ui very padded segment">
                    {businessEntity && businessEntity.images.logoSrc ? <Image src={businessEntity.images.logoSrc} id="logo" /> : ''}
                    <div className="ui basic segment padding-none">
                        <h1 className="ui header">Revoked</h1>
                        <div className="ui basic segment padding-none">
                            <Button id="doneButton" className="ui primary button" onClick={this.handleSuccess}>Done</Button>
                        </div>                        
                    </div>
                    {revokeSignedWaiverList}
                </div>
            </section>
            :
            <section>
                <Segment padded='very'>
                    <Dimmer active inverted>
                      <Loader content='Loading' />
                    </Dimmer>
                </Segment>
            </section>
        )
    }
}

export default connect(
    state => ({
        revokeSignedWaiver: state.revokeSignedWaiver,
        businessEntity: state.additionalInformation.businessEntity,
        signingProcess: state.signingProcess
    }),{}
)(RevokeSignedWaiver)
