import React from 'react'
import { Form, Label } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import moment from 'moment'

export default ({
    required,
    label,
    placeholder,
    input,
    meta: { touched, error },
    minDate,
    id
}) => 
    <Form.Field required={required}>
        <label htmlFor={id}>{label}</label>
        <DatePicker 
            selected={input.value}
            placeholderText={placeholder}
            onChange={date => input.onChange(moment.utc(date.format('MMM D, YYYY')).endOf('day'))}
            required={required}
            error={!!touched && !!error}
            dateFormat="dddd, MMMM D, YYYY"
            minDate={minDate}
            id={id}
        />
        {touched && (error && <Label pointing basic color='red'> {error} </Label>)}
    </Form.Field>