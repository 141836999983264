import React from 'react'
import { Form, Label } from 'semantic-ui-react'

export default ({
    input,
    name,
    required,
    label,
    type,
    meta: { touched, error },
    translate,
    id
}) =>
    <Form.Field required={required}>
        <div className={touched && error ? 'field ui checkbox invalid-checkbox' : 'field ui checkbox'}>
            <input
                id={id}
                type='checkbox'
                name={input.name}
                data-checked={!!input.value}
                checked={!!input.value}
                onChange={({target}) => input.onChange(target.checked)}
                required={required} />
            <label htmlFor={id} style={{cursor: 'pointer'}}>{label}</label>
        </div>
        <br/>
        {touched && (error && <Label pointing basic color='red' id={`error_${label.replace(/ /g, '')}`}>{ error.text ? translate(error.text, { count: error.value }): translate(error)} </Label>)}
    </Form.Field>