import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchPost } from '../apiCalls/index'
import { LANGUAGE  } from '../constants'

function* loadLanguages(action) {

    try{
        const response = yield call(fetchPost,`doc/language`)
        if(response.payload){
            yield put({type: LANGUAGE.SUCCESS, payload: response.payload.languages})
        }
        else{
            yield put({type: LANGUAGE.FAIL, payload: 'No languages were loaded'})
        }
    }
    catch(e){
        yield put({type: LANGUAGE.FAIL, payload: e.message})
    }
}

export function* waiverLanguage() {
    yield [
        takeLatest(LANGUAGE.REQUEST, loadLanguages)
    ]
}
