import React from 'react'
import InputMask from 'react-input-mask'
import { Field, FormSection } from 'redux-form'
import { Form, Label } from 'semantic-ui-react'
import renderSelectField from './selectField'

export default ({
  name,
  placeholder,
  identificationType,
  identificationTypeOptions,
  label,
  autoComplete,
  required,
  translate,
  id
}) =>
    <FormSection name={name}>
        <Form.Field>
            <Field
                id={`${id}-type`}
                name={'type'}
                component={renderSelectField}
                label={label}
                required={required}
                placeholder={placeholder}
                options={identificationTypeOptions}
                translate={translate}/>
            <Field id={`${id}-number`} name={`number`}
                component={({
                    input,
                    required,
                    meta: {
                     touched,
                     error
                    },
                    translate
                }) => !identificationType ? null :
                    <Form.Field error={!!touched && !!error}>
                        <InputMask id={id} {...input} autoComplete={autoComplete} mask="****" maskChar="" alwaysShowMask={true} />
                        {touched && (error && <Label pointing basic color='red' id={`error_${id}`}> {translate(error)} </Label>)}
                    </Form.Field>}
                required={required}
                translate={translate}
            />
        </Form.Field>
    </FormSection>